<template>
  <div id="app">
    <Header v-if="!isBlankLayout" />
    <sideNav v-if="!isBlankLayout" />
    <router-view />
    <div v-if="!isBlankLayout" class="chatbot-container" :class="{ open: isChatbotVisible }">
      <div class="chatbot-header" @click="toggleChatbot"> Sizzle Chat </div>
    <iframe
    src="https://sizzlechat.streamlit.app/?embed=true"
    class="floating-iframe"
    title="floating-iframe"></iframe>
    </div>
    <scrollTopBtn v-if="!isBlankLayout" />
    <Footer v-if="!isBlankLayout" />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import scrollTopBtn from "@/components/ScrollTopBtn.vue";
import sideNav from "@/components/sideNav.vue";
import BlankLayout from "@/components/BlankLayout.vue"; // BlankLayout 컴포넌트 가져오기

export default {
  components: {
    Header,
    Footer,
    scrollTopBtn,
    sideNav,
  },
  computed: {
    isBlankLayout() {
      // 현재 라우트의 meta.layout이 BlankLayout인지 확인하여 Boolean 값을 반환
      return this.$route.meta.layout === BlankLayout;
    },
  },
  data(){
    return{
      isChatbotVisible: false,
    };
  },
  methods:{
    toggleChatbot(){
      this.isChatbotVisible = !this.isChatbotVisible;
    }
  }
};
</script>

<style>
@import url("@/assets/styles/pretendard.css");
@import url("@/assets/styles/variables.css");
@import url("@/assets/styles/app.css");
@import url("@/assets/styles/chatbot.css");
</style>
