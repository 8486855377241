<template>
  <div>
    <iframe
      :src="streamlitUrl"
      width="100%"
      height="600px"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      streamlitUrl: "https://sizzlechat.streamlit.app/?embed=true" // 배포된 Streamlit 앱 URL
    };
  }
};
</script>
