<template>
<div class="footer">
  <div class="footer-container" style="color:#ffffffb5; font-weight:400;">
    <div class="footer-about" style="text-align:left; padding-top:0.5rem; padding-left:1rem;">
      <p>© 2024 자취생을 위한 레시피. All Rights Reserved.</p>
      <p style="margin-bottom:5.5rem;">자취생을 위한 쉽고 간단한 레시피를 제공합니다.</p>
    </div>
    
    <div class="footer-links" style="padding-left: 1rem;">
      <a href="">홈</a> | 
      <a href="/categories">레시피 카테고리</a> | 
      <a href="">문의하기</a> | 
      <a href="">개인정보 처리방침</a> | 
      <a href="">이용약관</a>
    </div>      
    
    <div class="footer-contact">
      <p style="text-align:end; padding-right:1rem; margin-top:-3.5rem;">문의 이메일: levicomo@naver.com</p>
    </div>
    
    <div class="footer-social" style="text-align:end; padding-right:1rem;">
      <a href="" target="_blank">Facebook</a> |
      <a href="" target="_blank">Instagram</a>
    </div>
  </div>
</div>
</template>


<script>
export default {

}
</script>


<style scoped>
.footer {
  margin: 0 auto;
  width: 100%;
  background-color: var(--primary-color);
  box-sizing: border-box;
  height: 200px;
}
</style>