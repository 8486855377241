<template>
  <div class="section">
    <div class="section-recipe-title addfont2" style="margin-bottom: 9rem">
      <unicon
        name="restaurant"
        class="icon-restaurant-style plus"
        width="40px"
        height="40px"
      />
      냉장고 신선하게 유지하기
    </div>
    <div class="box1">
      <div class="box4">
        <div class="info-imgbox-container">
          <div class="background-sqare red" />
          <div class="info-imgbox">
            <img :src="imgUrl2" loading="lazy"/>
          </div>
        </div>

        <div class="titlebox">
          식료품을 섞지 마세요
          <div class="contentbox" v-html="htmlContent"></div>
        </div>
      </div>
      <div class="devider" />
      <div class="box3">
        <div class="titlebox">
          통째로 보관하세요
          <div class="contentbox" v-html="htmlContent2"></div>
        </div>
        <div class="info-imgbox-container">
          <div class="background-sqare-right" />
        <div class="info-imgbox-right">
          <img :src="imgUrl3" loading="lazy"/>
        </div>
      </div>
      </div>
      <div class="devider" />
      <div class="box4">
        <div class="info-imgbox-container">
          <div class="background-sqare yellow" />
        <div class="info-imgbox">
          <img :src="imgUrl4" />
        </div>
        </div>
        <div class="titlebox">
          냉장고, 냉동고 문을 자주 열지 마세요
          <div class="contentbox" v-html="htmlContent3"></div>
        </div>
      </div>
      <div class="devider" />
      <div class="box3">
        
        <div class="titlebox">
          식품을 제자리에 보관하세요
          <div class="contentbox" v-html="htmlContent4"></div>
        </div>
      

          <div class="info-imgbox-container">
          <div class="background-sqare-right green" />
              <div class="info-imgbox-right">
          <img :src="imgUrl" />
        </div>
        </div>
      </div>
      <div class="devider" />
      <div class="box4">
        <div class="info-imgbox-container">
          <div class="background-sqare" />
        <div class="info-imgbox">
          <img :src="imgUrl6" />
        </div>
        </div>
        <div class="titlebox">
          ＇우선 섭취 ' 상자를 사용해 보세요
          <div class="contentbox" v-html="htmlContent6"></div>
        </div>
      </div>
      <div class="devider" />
      <div class="box3">
        <div class="titlebox">
        차갑게 보관하는 것을 구분해 보세요
          <div class="contentbox" v-html="htmlContent7"></div>
        </div>
         <div class="info-imgbox-container">
          <div class="background-sqare-right purple" />
              <div class="info-imgbox-right">
          <img :src="imgUrl5" />
        </div>
         </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgUrl: `https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2Fpexels-nc-farm-bureau-mark-2893525.jpg?alt=media&token=6add3eaf-b9c4-4438-af34-0a8f52b262f6`, 
      imgUrl2:`https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2Fpexels-rauf-allahverdiyev-561368-1367243.jpg?alt=media&token=a6d6235c-ba63-4b46-ada5-b4e6043659cf`,
      imgUrl3:`https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2Fcarrots-673201_640%20(1).jpg?alt=media&token=7e629e2e-bba5-4e22-b8ea-b36ed6671f70`,
      imgUrl4:`https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2Fpexels-enginakyurt-24595910.jpg?alt=media&token=fb1bc96b-4558-4a14-a6f0-2b057e7ff8ae`,
      imgUrl5:`https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2Fblue-3952596_640.jpg?alt=media&token=5fb43cd7-7d04-41c6-8b2b-d391c2056d12`,
      imgUrl6:`https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2Fpexels-angele-j-35172-139749.jpg?alt=media&token=63eccf28-c90c-4688-8b7d-79f10125b890`,
      htmlContent: `생고기와 해산물은 따로 구분된 칸에 보관하고, 과일과 야채는 다른 칸에 보관하여 식품의 부패와 낭비를 줄일 수 있습니다.<br><br>이렇게 하면 고기나 해산물에서 나온 육즙이 다른 음식에 묻는 것을 방지할 수 있습니다`,
      htmlContent2: `날 음식과 익히지 않은 음식을 미리 자르지 마세요.<br>조각으로 자르면 신선한 고기, 과일, 야채는 통째로 보관한 것보다 훨씬 빨리 상할 수 있습니다.<br><br>남은 재료는 냉동실에 넣어 신선함을 유지하세요.`,
      htmlContent3: `냉장고와 냉동실 문을 자주 열면 차가운 공기가 빠져나가고 뜨거운 공기가 들어와 냉장고 내부 온도가 상승하게 됩니다.<br><br>또한, 습한 공기가 냉장고 안으로 들어오면서 음식과 내부에 물방울이나 서리가 생겨, 곰팡이가 번식할 수도 있습니다.`,
      htmlContent4: `냉장고에는 유형에 따라 설계된 구획이 있습니다.<br><br>과일과 야채는 일반적으로 하단 선반에 있는 야채 전용 칸에 보관하며, 계란은 계란 트레이에 보관하는 것이 좋습니다.<br>질감을 부드럽게 유지하는 것들은 문 선반에 보관하세요.`,
      htmlContent6: `남은 저녁 식사나 이미 개봉해버린 농산물은 신선도를 유지하기 위해 밀폐 용기에 보관하는 것이 좋습니다.<br><br>밀폐 용기는 음식이 마르거나 눅눅해지는 것을 막아주며, 냉동실은 음식이 맛과 질감이 변하는 것을 방지해줍니다.`,
      htmlContent7: `일부 야채는 냉장시 식감이 변할 수 있으니 주의해주세요.<br><br>예를 들어, 토마토, 마늘, 양파는 냉장고에 보관하면 부드러워지거나 눅눅해질 수 있어 실온 보관이 더 적합합니다.<br>또한 바나나, 감자, 꿀과 같은 식품도 실온 보관이 좋습니다.`,
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
@import url("@/assets/styles/info.css");
@import url("@/assets/styles/nanumneo.css");
</style>