import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null, 
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user; 
    },
    CLEAR_USER(state) {
      state.user = null; 
    },
  },
  actions: {
    setUser({ commit }, user) {
      commit("SET_USER", user);
    },
    clearUser({ commit }) {
      commit("CLEAR_USER");
    },
  },
  getters: {
    getUser: (state) => state.user, 
  },
  modules: {},
  plugins: [
    createPersistedState({
      storage: window.localStorage, // 상태를 로컬 스토리지에 저장
    }),
  ],

});
