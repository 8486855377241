<template>
  <div>
    <div class="post"></div>
    <div class="writer">
      <div class="writer-photo">
        <img :src="writerImg" />
      </div>
      <div class="writer-info">
        <div class="writer-info-container">
        <div class="writer-name">
          {{ writer }}
        </div>
        <div class="writer-email">
          {{ writerEmail }}
        </div>
        </div>
        <div
          class="writer-description"
          v-show="writerDesc"
          v-html="writerDesc"
          style="font-size: 14px; margin-top: -5px"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    postData: {
      type: Object,
      required: true,
    },
    writer: {
      required: true,
    },
    writerDesc: {
      required: false,
    },
    writerEmail:{
      required: true,
    },
    writerImg: {
      required: false,
    },
  },
  created() {
    //console.log(this.postData);
  },
};
</script>

<style scoped>
@import url("@/assets/styles/post.css");
</style>
