<template>
  <div class="section" style="width: 90%; margin: 5rem auto 10rem auto">
    <input
      class="title-input"
      v-model="title"
      placeholder="이곳에 제목을 입력하세요."
    />
    <vue-editor
      ref="editor"
      v-model="content"
      placeholder="이곳에 내용을 입력하세요."
    />
    <div class="btn-area">
      <span
        class="btn submit"
        style="font-size: 1.2rem; font-weight: 600; margin-top: 1rem"
        @click="saveContent()"
      >
        작성하기
      </span>
      <Modal ref="modal" @modalClosed="onModalClosed" />
      
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { selectLocalImage } from "../../back-end/routes/storeImage";
import axios from "axios";
import { mapGetters } from "vuex";
import Modal from '../components/Modal.vue';

export default {
  components: {
    VueEditor,
    Modal,
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  data() {
    return {
      title: "",
      content: "",
      email: "",
    };
  },
  created() {
   // console.log(`이건가?`, this.getUser?.email); // email 속성에 접근 가능한지 확인
  },
  mounted() {
    const toolbar = this.$refs.editor.quill.getModule("toolbar");
    toolbar.addHandler("image", () => selectLocalImage(this.$refs.editor));
    // selectLocalImage 함수에 editor 참조 전달
    // 이미지 선택 핸들러는 이미 모듈 파일에 정의되었으므로 필요 없음
  },
  methods: {  
    async saveContent() {
      try {
        const response = await axios.post("/api/tables/edit", {
          title: this.title,
          body: this.content,
          email: this.getUser.email,  
        });
       // console.log("Saved content:", response.data);
        this.$refs.modal.openModal("게시물 작성 완료");
      } catch (error) {
        console.error("Error saving content:", error);
      }
    },
     onModalClosed() {
      // 모달이 닫힌 후 라우트 이동
      this.$router.push("/tables");
    }
  },
};
</script>

<style scoped>
.title-input {
  outline: none;
  border: none;
  border-bottom: 1px solid var(--grey);
  width: 100%;
  height: 1rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.4rem;
  font-weight: 600;
  padding-top: .5rem;
}
</style>