<template>
  <div class="section rrr" style="width: 90%; margin: 5rem auto 10rem auto">
    <input v-if="b" class="title-input" v-model="b.title"/>
    <vue-editor ref="editor" v-show="b" v-model="b.body"/>
    <div class="btn-area">
      <span
        class="btn submit"
        style="font-size: 1.2rem; font-weight: 600; margin-top: 1rem" @click="updatePost()">수정하기</span>
        <Modal ref="modal" @modalClosed="onModalClosed" />
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";
import { selectLocalImage } from "../../back-end/routes/storeImage"; 
import Modal from '../components/Modal.vue';

export default {
  components: {
    VueEditor,
    Modal
  },
  data() {
    return {
      title: "",
      body: "",
      b: null
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
  b(newValue) {
    if (newValue) {
      this.$nextTick(() => {
        if (this.$refs.editor && this.$refs.editor.quill) {
          const toolbar = this.$refs.editor.quill.getModule('toolbar');
          toolbar.addHandler('image', () => selectLocalImage(this.$refs.editor));
         // console.log("이미지 업로드 핸들러가 등록되었습니다.");
        } else {
          console.error("Quill 에디터가 초기화되지 않았습니다.");
        }
      });
    }
  }
},
  methods: {
    async fetchData() {
      try {
        const id = this.$route.params.id;
        const response = await axios.get(`/api/tables/${id}`);
        this.b = response.data;
       // console.log(this.b);
      } catch (error) {
        console.error("데이터 가져오기 실패:", error);
      }
    },
    
    async updatePost() {
      try {
        const id = this.$route.params.id;
        const response = await axios.put(`/api/tables/modify/${id}`, {
          title: this.b.title,
          body: this.b.body,
        });
        //console.log("수정 성공:", response.data);
        this.$refs.modal.openModal("게시물 수정 완료");
      } catch (error) {
        console.error("게시물 수정 중 에러 발생:", error);
        this.$refs.modal.openModal("게시물 수정 실패");
      }
    },
    onModalClosed() {
      // 모달이 닫힌 후 라우트 이동
      this.$router.push(`/tables/${this.$route.params.id}`);
    }
  }
};
</script>

<style scoped>
.title-input {
  outline: none;
  border: none;
  border-bottom: 1px solid var(--grey);
  width: 100%;
  height: 1rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.4rem;
  font-weight: 600;
  padding-top: .5rem;
}
.section.rrr{
  font-family: 'NanumSquareNeo',sans-serif;
}
</style>