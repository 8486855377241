import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../views/Main.vue";
import Categories from "../views/Categories.vue";
import Tables from "../views/Tables.vue";
import DetailRecipe from "../views/DetailRecipe.vue";
import DetailTable from "../views/DetailTable.vue";
import PostEdit from "../views/PostEdit.vue";
import Login from "../views/Login.vue";
import SignUp from "../views/SignUp.vue";
import ModifyTable from "../views/ModifyTable.vue";
import Bookmarks from "../views/Bookmarks.vue";
import MyPost from "../views/MyPost.vue";
import ModifyInfo from "../views/ModifyInfo.vue";
import RecipeEdit from "../views/RecipeEdit.vue";
import Refrigerator from "../views/Refrigerator.vue";
import RandomMenu from "../views/randomMenu.vue";
import Information from "../views/Information.vue";
import Tip from "../views/Tip.vue";
import MyRecipe from "../views/MyRecipe.vue";
import Chat from "../components/Chat.vue";
import CuisineTip from "../views/CuisineTip.vue";
import BlankLayout from "../components/BlankLayout.vue";
import ChatBot from "../components/ChatBot.vue";
import RecipeMemo from "../views/RecipeMemo";
import MyMemo from "../views/MyMemo.vue"
import RecipeChatbot from "../views/RecipeChatbot"

Vue.use(VueRouter);

const routes = [
  {
    path: "/chatbot",
    name: "chatbot",
    component: ChatBot,
  },
  {
    path: "/chat",
    name: "chat",
    component: Chat,
  },
  {
    path: "/mypage/myrecipe",
    name: "my-recipe",
    component: MyRecipe,

  },
  {
    path: "/information/tip",
    name: "information-tip",
    component: Tip,
  },
  {
    path: "/information",
    name: "information",
    component: Information,
  },
  {
    path: "/categories/randommenu",
    name: "random-menu",
    component: RandomMenu,
  },

  {
    path: "/categories/refrigerator",
    name: "refrigerator",
    component: Refrigerator,
  },
  {
    path: "/tables/modify/:id",
    name: "modify-table",
    component: ModifyTable,
  },
  {
    path: "/",
    name: "home",
    component: Main,
  },
  {
    path: "/categories",
    name: "categories",
    component: Categories,
  },
  {
    path: "/tables",
    name: "tables",
    component: Tables,
  },
  {
    path: "/mypage/mypost",
    name: "my-post",
    component: MyPost,
  },

  {
    path: "/mypage/bookmarks",
    name: "bookmarks",
    component: Bookmarks,
  },
  {
    path: "/mypage/info",
    name: "modify-info",
    component: ModifyInfo,
  },
  {
    path: "/categories/detail/:id",
    name: "detail-recipe",
    component: DetailRecipe,
  },
  {
    path: "/tables/edit",
    name: "post-edit",
    component: PostEdit,
  },
  {
    path: "/tables/:id",
    name: "detail-table",
    component: DetailTable,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/signup",
    name: "sign-up",
    component: SignUp,
  },
  {
    path: "/recipe-edit",
    name: "recipe-edit",
    component: RecipeEdit,
  },
  {
    path: "/categories/detail/:id/tipId",
    name: "cuisine-tip",
    component: CuisineTip,
    meta: { layout: BlankLayout },
  },
  {
    path: "/categories/detail/:id/memo",
    name: "recipe-memo",
    component: RecipeMemo,
    meta: { layout: BlankLayout },
  },
  {
    path: "/mypage/mymemo",
    name: "my-memo",
    component: MyMemo,
  },
  {
    path: "/categories/recipechatbot",
    name: "recipe-chatbot",
    component: RecipeChatbot,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
