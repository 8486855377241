<template>
  <div class="tip-container">
    <div class="tip-list">
    <!-- 댓글 목록 표시 -->
    <div v-for="(comment, index) in comments" :key="index" class="tip-comment">
      <span>
        <span class="tip-nickname">
        - {{comment.nickName}} :</span> 
      <span class="tip-content">{{ comment.content }}</span>
      <span class="tip-createdAt">{{comment.createdAt}}</span>
      <span v-if="comment.author === useremail">
       <unicon
          name="times-square"
          class="icon-square-style tip"
          width="15px"
          height="15px"
          @click="deleteComment(comment._id)"
          />
      </span>
      </span>
      <div v-if="index !== comments.length - 1" class="horizontal-line-tip"></div>
    </div>
    </div>
    <!-- 댓글 입력 박스 -->
    <div class="tip-content-box">
      <textarea
        class="tip-text-area"
        v-model="newComment"
        placeholder=""
      ></textarea>
      <span class="tip-submit" @click="submitComment">작성</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default{
   computed: {
    ...mapGetters(["getUser"]),
  },
  data(){
    return{
      newComment:"",
      comments:[],
      recipeId:"",
      email:"",
      useremail:"",
    };

  },
  created(){
    this.recipeId = this.$route.params.id;
    console.log(this.recipeId);
    this.useremail=this.getUser.email;
  },
  mounted(){
    this.loadComments();
  },
  methods:{
    async submitComment(){
      const author = this.getUser.email;
      const nickName = this.getUser.displayName;
      console.log(author,nickName);
      if(this.newComment.trim()){
        try{
          const response = await axios.post(`/api/cuisinetip/${this.recipeId}/tips`,{
            content : this.newComment,
            author : author,
            nickName : nickName
            
          });

          this.comments.push(response.data);
          this.newComment="";

        }catch(error){
          console.error(`에러남`,error);
        }
      }
    },
    async loadComments(){
        try{
          const response = await axios.get(`/api/cuisinetip/${this.recipeId}/tips`);
          this.comments = response.data;
        }catch(error){
          console.error("댓글로드에러",error);
        }
      },
       async deleteComment(tipId) {
      try {
        await axios.delete(`/api/cuisinetip/${this.recipeId}/tips/${tipId}`);
        // 삭제된 댓글을 로컬 `comments` 배열에서도 제거
        this.comments = this.comments.filter((comment) => comment._id !== tipId);
      } catch (error) {
        console.error("댓글 삭제 중 에러 발생:", error);
      }
    },
  }
}
</script>

<style scoped>
@import url("@/assets/styles/detail-recipe-tip.css");
</style>