<template>
  <div class="header">
    <div class="logo">
      <!-- <a
        href="/"
        style="margin: 0; padding: 0; font-size: 40px; font-weight: 800"
        >로고</a
      > -->
      <a href="/"><img src="@/assets/images/icon.png" alt="logo" /></a>
    </div>
    <div class="login-section">
    
        <div v-if="getUser" @click="routePush('/chat')">
        <unicon
          name="envelope"
          class="icon-style-envelope"
          width="20px"
          height="20px"
        />

      </div>
      <span v-if="!getUser" class="btn-signin" @click="routePush('/login')"
        >로그인</span
      >
      <span v-if="!getUser" class="btn-signup" @click="routePush('/signup')"
        >회원가입</span
      >
      <span v-if="getUser">{{ getUser?.displayName }}</span>
      <span v-if="getUser" @click="logout">로그아웃</span>
    </div>

    <div class="nav">
      <span class="menu" 
      :class="checkActiveNav('/information')"
      style="border-radius:15px 0 0 0; "
      @click="routePush('/information')"
      @mouseover="showSubnav3"
      @mouseleave="hideSubnav3"
        >생활정보</span
      >
      <span
        class="menu"
        :class="checkActiveNav('/categories')"
        @click="routePush('/categories')"
        @mouseover="showSubnav2"
        @mouseleave="hideSubnav2"
        >레시피</span
      >

      <span
        class="menu"
        :class="checkActiveNav('/tables')"
        @click="routePush('/tables')"
        >커뮤니티</span
      >
      <span
        class="menu"
        :class="checkActiveNav('/mypage/mypost')"
        @click="routePush_signCheck('/mypage/mypost')"
        style="border-radius:0 15px 0 0;"
        @mouseover="showSubnav"
        @mouseleave="hideSubnav"
        >마이페이지</span
      >
    </div>

    
    <div
      class="nav subnav3"
      :class="{ 'is-visible': isSubnav3Visible }"
      @mouseover="showSubnav3"
      @mouseleave="hideSubnav3"
    >
      <span class="menu" style="font-family:'NanumSquareNeo',sans-serif;" @click="routePush_info('')">냉장고 신선하게 유지하기</span>
      <span class="menu" style="font-family:'NanumSquareNeo',sans-serif;" @click="routePush_info('tip')"
        >식재료 보관 팁</span
      >
    </div>


    <div
      class="nav subnav2"
      :class="{ 'is-visible': isSubnav2Visible }"
      @mouseover="showSubnav2"
      @mouseleave="hideSubnav2"
    >
      <span class="menu" style="font-family:'NanumSquareNeo',sans-serif;" @click="routePush_categories('')">레시피 검색</span>
      <span class="menu" style="font-family:'NanumSquareNeo',sans-serif;" @click="routePush_categories('refrigerator')"
        >냉장고를 부탁해</span
      >
      <span class="menu" style="font-family:'NanumSquareNeo',sans-serif;" @click="routePush_categories('randommenu')"
        >오늘 뭐 먹지?</span
      >
       <span class="menu" style="font-family:'NanumSquareNeo',sans-serif;" @click="routePush_categories('recipechatbot')"
        >레시피 메이트</span>
    </div>
    <div
      class="nav subnav"
      :class="{ 'is-visible': isSubnavVisible }"
      @mouseover="showSubnav"
      @mouseleave="hideSubnav"
    >
      <span class="menu" style="font-family:'NanumSquareNeo',sans-serif;" @click="routePush_myPage('mypost')">나의 스토리</span>
      <span class="menu" style="font-family:'NanumSquareNeo',sans-serif;" @click="routePush_myPage('myrecipe')"
        >나의 레시피</span
      >
      <span class="menu" style="font-family:'NanumSquareNeo',sans-serif;" @click="routePush_myPage('mymemo')"
        >나의 메모장</span>
      <span class="menu" style="font-family:'NanumSquareNeo',sans-serif;" @click="routePush_myPage('bookmarks')">북마크</span>
      <span class="menu" style="font-family:'NanumSquareNeo',sans-serif;" @click="routePush_myPage('info')">개인정보 수정</span>
    </div>
  </div>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";
import { mapGetters, mapMutations } from "vuex";

export default {
  computed: {
    ...mapGetters(["getUser"]),
  },
  data() {
    return {
      isSubnavVisible: false,
      isSubnav2Visible: false,
      isSubnav3Visible: false,
    };
  },
  methods: {
    ...mapMutations(["CLEAR_USER"]),

    showSubnav() {
      if (this.$route.path.startsWith("/mypage")) {
        this.isSubnavVisible = true;
      }
    },
    hideSubnav() {
      this.isSubnavVisible = false;
    },
    checkActiveNav(route) {
      if (route === "/") {
        if (route === this.$route.path) {
          return "active-menu";
        }
      } else if (this.$route.path.slice(0, route.length) === route) {
        return "active-menu";
      }
    },
    async logout() {
      const auth = getAuth();
      try {
        await signOut(auth);
        if (this.$route.path !== "/") {
          this.$router.push("/");
        }
        this.CLEAR_USER();
      } catch (error) {
        alert(error.message);
        console.error(error);
      }
    },

    routePush(route) {
      if (this.$route.path !== route) {
        this.$router.push(route);
      }
    },
    routePush_signCheck(route) {
      if (!this.$route.path.startsWith("/mypage")) {
        if (this.getUser) {
          this.$router.push(route);
        } else {
          this.$router.push("/login");
        }
      }
    },
    routePush_myPage(route) {
      if (this.$route.path !== `/mypage/${route}`) {
        this.$router.push(`/mypage/${route}`);
      }
    },
    routePush_categories(route) {
      const targetRoute = `/categories/${route}`.replace(/\/+$/, ""); // 끝 슬래시 제거
      if (this.$route.path.replace(/\/+$/, "") !== targetRoute) {
        this.$router.push(targetRoute);
      }
    },
    routePush_info(route) {
      const targetRoute = `/information/${route}`.replace(/\/+$/, ""); // 끝 슬래시 제거
      if (this.$route.path.replace(/\/+$/, "") !== targetRoute) {
        this.$router.push(targetRoute);
      }
    },
    showSubnav2() {
      if (this.$route.path.startsWith("/categories")) {
        this.isSubnav2Visible = true;
      }
    },
    hideSubnav2() {
      this.isSubnav2Visible = false;
    },
    // 클릭 후에도 서브메뉴가 보이게 설정
    routePushAndShowSubnav2(route) {
      if (this.$route.path !== route) {
        this.$router.push(route).then(() => {
          this.isSubnav2Visible = true; // 마우스 오버 후 서브메뉴 표시
        });
      } else {
        this.isSubnav2Visible = true; // 이미 같은 경로에 있을 경우 서브메뉴 표시
      }
    },
    showSubnav3() {
      if (this.$route.path.startsWith("/information")) {
        this.isSubnav3Visible = true;
      }
    },
    hideSubnav3() {
      this.isSubnav3Visible = false;
    },
    // 클릭 후에도 서브메뉴가 보이게 설정
    routePushAndShowSubnav3(route) {
      if (this.$route.path !== route) {
        this.$router.push(route).then(() => {
          this.isSubnav3Visible = true; // 마우스 오버 후 서브메뉴 표시
        });
      } else {
        this.isSubnav3Visible = true; // 이미 같은 경로에 있을 경우 서브메뉴 표시
      }
    },
  },
};
</script>

<style scoped>
@import url("@/assets/styles/header.css");
@import url("@/assets/styles/nanumneo.css");
</style>
