<template>
  <div class="page section">
    <div class="banner section">
      <div class="slide section" style="align-items: center">
        <div
          class="swiper-button-prev plus swiper-button-black"
          slot="button-prev"
        ></div>
        <swiper class="swiper banner-slide" :options="bannerSwiperOption">
          <swiper-slide
            class="banner-img"
            v-for="(img, idx) in images"
            :key="'banner-img' + idx"
            style="width: 100%; height: 100%"
          >
            <img :src="img" />
          </swiper-slide>

          <div
            class="swiper-pagination swiper-pagination-white"
            slot="pagination"
          ></div>
        </swiper>
        <div
          class="swiper-button-next plus swiper-button-black"
          slot="button-next"
        ></div>
      </div>
    </div>
    <div class="index-sub-title subAddfont">씨즐과 함께하는 특별한 순간</div>
    <div class="horizontal-line mainpage"></div>
    <div class="shortcut section">
      <div class="icon-container">
        <div class="shortcut-icon bc-red">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/icon%2Fcooking.png?alt=media&token=dfad4f2a-94cc-47b4-8755-fd2da0d87508"
          @click="navigateToIcon(`/information/tip`)"/>
          <span class="sc-icon-text">식재료 보관법</span>
        </div>
        <div class="shortcut-icon bc-green">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/icon%2Frecipe.png?alt=media&token=568d3fe8-c9f7-4a7f-8a80-6cfc025ee6a3"
          @click="navigateToIcon(`/categories`)"/>
          <span class="sc-icon-text">레시피 검색</span>
        </div>
        <div class="shortcut-icon bc-purple">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/icon%2Ffree-icon-fridge-630298411.png?alt=media&token=aea8d3cd-4d20-4561-8e53-b330689d6e6b"
          @click="navigateToIcon(`/categories/refrigerator`)"/>
          <span class="sc-icon-text">냉장고를 부탁해</span>
        </div>
        <div class="shortcut-icon">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/icon%2Fcooking%20(1).png?alt=media&token=d3a7018d-e41f-4465-ae6c-7ae0bbe863ea"
          @click="navigateToIcon(`/tables`)"/>
          <span class="sc-icon-text">키친 스토리</span>
        </div>
      </div>
    </div>

    <div class="most-view section">
      <div class="section-title addfont">주목받고 있는 인기 레시피</div>
      <div class="most-view-recipe">
        <div class="big-test-container">
          <img
            :src="mostviewRecipe[0]?.thumbnailImg"
            @click="navigateToRecipe(`${mostviewRecipe[0]?._id}`)"
          />
        </div>
        <div class="test-container">
          <img
            :src="mostviewRecipe[1]?.thumbnailImg"
            @click="navigateToRecipe(`${mostviewRecipe[1]?._id}`)"
          />
        </div>
        <div class="test-container">
          <img
            :src="mostviewRecipe[2]?.thumbnailImg"
            @click="navigateToRecipe(`${mostviewRecipe[2]?._id}`)"
          />
        </div>
        <div class="test-container">
          <img
            :src="mostviewRecipe[3]?.thumbnailImg"
            @click="navigateToRecipe(`${mostviewRecipe[3]?._id}`)"
          />
        </div>
        <div class="test-container">
          <img
            :src="mostviewRecipe[4]?.thumbnailImg"
            @click="navigateToRecipe(`${mostviewRecipe[4]?._id}`)"
          />
        </div>
      </div>
    </div>

    <div class="weekly-recipe section">
      <div class="section-title addfont">이런 요리는 어떠세요, 키친 스토리</div>
      <div class="weekly-recipe-list">
        <div
          class="thumbnail"
          v-for="(post, idx) in posts.slice(0,4)" 
          :key="'weekly-recipe' + idx"
          @click="navigateToPost(`${post._id}`)"
        >
          <img :src="recentpost[idx]" />
        </div>
         
      </div>
      <div class="recentpost-title-container">
      <div class="recentpost-title"
         v-for="(post, idx) in posts.slice(0,4)"
         :key="idx">
         <div>{{post.title}}</div>
          </div>
      </div>
    </div>
    <div class="today-recipe section">
      <div class="section-title addfont" style="text-align:center;">가장 많이 추천받은 베스트 레시피</div>
       <div class="horizontal-line mainpage" style="margin-top:2rem;width:20%;margin-left:40%;margin-bottom:3rem;"></div>
      <div class="slide section" style="align-items:center;">
        <div
            class="swiper-button-prev poprecipebutton swiper-button-black"
            slot="button-prev"
          ></div>
        <swiper :options="todayRecipeswiperOption">
          
          <swiper-slide
            class="today-recipe-banner"
            v-for="(slide, index) in popularRecipe"
            :key="'banner-img' + index"
          >
            <div
              class="today-recipe-thumbnail"
              :key="'todatRecipeThumbnail' + index"
            >
              <img :src="slide.thumbnailImg"
              @click="navigateToRecipe(`${slide?._id}`)" />
            </div>
            <div class="today-recipe-container">
              <div class="today-recipe-title" :key="'todatRecipeTitle' + index">
                {{ slide.title }}
              </div>
              <div
                class="today-recipe-context"
                :key="'todatRecipeContext' + index"
              >
                {{ slide.content }}
              </div>
            </div>
          </swiper-slide>
         
        </swiper>
         <div
            class="swiper-button-next poprecipebutton swiper-button-black"
            slot="button-next"
          ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import axios from "axios";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      popularRecipe:[],
      mostviewRecipe: [],
      recentpost: [],
      posts:[],
      searchActive:false,

      bannerSwiperOption: {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      todayRecipeswiperOption: {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      images: ["https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/banner%2FKakaoTalk_20241027_170836498_02.jpg?alt=media&token=666ad904-bd7d-4761-aa36-cff5f394c127","https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/banner%2FKakaoTalk_20241027_170836498_01.jpg?alt=media&token=faf37465-ee8b-4bf3-b092-c68e69fbcfa4","https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/banner%2FKakaoTalk_20241027_170836498_03.jpg?alt=media&token=d85cff5a-64d0-418f-a716-d44837b90bf7","https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/banner%2F3.png?alt=media&token=57126d89-96ba-4a76-9732-8492847f0612"
      ],

      todayRecipeSlides: [
        {
          thumbnail: require("@/assets/data/img/img4.jpg"),
          title: "제목1",
          context: "내용1",
        },
        {
          thumbnail: require("@/assets/data/img/img5.jpg"),
          title: "제목2",
          context: "내용2",
        },
        {
          thumbnail: require("@/assets/data/img/img3.jpg"),
          title: "제목3",
          context: "내용3",
        },
        {
          thumbnail: require("@/assets/data/img/img2.jpg"),
          title: "제목4",
          context: "내용4",
        },
      ],
    };
  },
  async created() {
    await this.getMostviewRecipe();
    await this.getRecentPosts();
  },
  methods: {
    async getMostviewRecipe() {
     // console.log(`레시피 가져오기 실행됨`);
      const response = await axios.get(`/api/categories/mostview`);
      //console.log(response.data);
      this.mostviewRecipe = response.data.recipes;
      //console.log(this.mostviewRecipe);
      this.popularRecipe = response.data.poprecipes;
    //  console.log(this.popularRecipe);
    },

    async getRecentPosts() {
      try {
     //   console.log(`게시물 가져오기 실행됨`);
        const response = await axios.get(`/api/tables`);
      //  console.log(`게시물 받아오기`, response.data.posts);
        this.posts= response.data.posts;
        this.extractImages();
      } catch (error) {
        console.error(`게시물 받아오는도중 에러발생`, error);
      }
    },
    
    async extractImages(){
      try{
      const bodyValues = Object.values(this.searchActive ? this.searchResults : this.posts).map(post => post.body);

       const extractedImages = await bodyValues.map(body => this.extractImage(body));

       this.recentpost = extractedImages;
       }catch(error){
        console.error(`에러남`,error);
       }
    },
    extractImage(data) {
  const div = document.createElement('div');
  div.innerHTML = data;
  const img = div.querySelector('img');
  return img ? img.src : 'https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2FKakaoTalk_20241004_010718846.jpg?alt=media&token=bbd80d9e-5533-46fa-a77d-04a5fc9a7308'; // img 태그가 있으면 src 속성을, 없으면 디폴트 이미지
  },
    navigateToRecipe(path) {
      this.$router.push(`/categories/detail/${path}`);
    },
    navigateToPost(path){
      this.$router.push(`/tables/${path}`);
    },
    navigateToIcon(path){
      this.$router.push(`${path}`);
    }
  },
};
</script>

<style scoped>
@import url("@/assets/styles/main.css");
@import url("@/assets/styles/nanumneo.css");
</style>
