<template>
  <div class="sideNav">
    <!-- 사이드 네비게이션 -->
    <div id="mySidenav" :class="{ sidenav: true, open: isNavOpen }">
      <a href="javascript:void(0)" class="closebtn" @click="closeNav"
        >&times;</a
      >
      <a class="sideNavCheck" @click="routePush('/information')">생활정보</a>
      <a class="sideNavCheck sub" @click="routePush('/information')"
        >- 냉장고 신선하게 유지하기</a
      >
      <a
        class="sideNavCheck sub"
        style="margin-bottom: 1rem"
        @click="routePush('/information/tip')"
        >- 식재료 보관 팁</a
      >
      <a class="sideNavCheck" @click="routePush('/categories')">레시피</a>
      <a class="sideNavCheck sub" @click="routePush('/categories')"
        >- 레시피 검색</a
      >
      <a class="sideNavCheck sub" @click="routePush('/categories/refrigerator')"
        >- 냉장고를 부탁해</a
      >
      <a
        class="sideNavCheck sub"
        @click="routePush('/categories/randommenu')"
        >- 오늘 뭐 먹지?</a
      >
      <a
        class="sideNavCheck sub"
        @click="routePush('/categories/recipechatbot')"
        >- 레시피 메이트</a
      >
      <a class="sideNavCheck sub"
      style="margin-bottom: 1rem"
      @click="routePush_signCheck('/recipe-edit')"
        >- 레시피 작성하기</a>

      <a class="sideNavCheck" @click="routePush('/tables')">커뮤니티</a>
      <a
        class="sideNavCheck sub"
        @click="routePush('/tables')"
        >- 키친 스토리</a
      >
      <a class="sideNavCheck sub"
      @click="routePush_signCheck('/tables/edit')"
        >- 게시물 작성하기</a>
        <a class="sideNavCheck sub"
      style="margin-bottom: 1rem"
      @click="routePush_signCheck('/chat')"
        >- 쪽지함</a>
      <a class="sideNavCheck" @click="routePush_signCheck('/mypage')"
        >마이페이지</a
      >
      <a class="sideNavCheck sub" @click="routePush_signCheck('/mypage/mypost')"
        >- 나의 스토리</a
      >
      <a
        class="sideNavCheck sub"
        @click="routePush_signCheck('/mypage/myrecipe')"
        >- 나의 레시피</a
      >
      <a
        class="sideNavCheck sub"
        @click="routePush_signCheck('/mypage/mymemo')"
        >- 나의 메모장</a
      >
      <a
        class="sideNavCheck sub"
        @click="routePush_signCheck('/mypage/bookmarks')"
        >- 북마크</a
      >
      <a class="sideNavCheck sub" @click="routePush_signCheck('/mypage/info')"
        >- 개인정보 수정</a
      >
    </div>
    <div class="hamburger-style">
      <unicon
        name="bars"
        @click="openNav"
        width="20px"
        height="30px"
        class="hamburger-icon"
      />
    </div>
  </div>
</template>


<script>
import { getAuth, signOut } from "firebase/auth";
import { mapGetters, mapMutations } from "vuex";

export default {
  computed: {
    ...mapGetters(["getUser"]),
  },
  data() {
    return {
      isNavOpen: false, //사이드 네비게이션 열림/닫힘 상태관리
    };
  },
  methods: {
    openNav() {
      this.isNavOpen = true;
    },
    closeNav() {
      this.isNavOpen = false;
    },
    checkActiveNav(route) {
      if (route === "/") {
        if (route === this.$route.path) {
          return "active-menu";
        }
      } else if (this.$route.path.slice(0, route.length) === route) {
        return "active-menu";
      }
    },
    routePush(route) {
      if (this.$route.path !== route) {
        this.$router.push(route);
      }
    },
    routePush_signCheck(route) {
      if (this.getUser) {
        this.$router.push(route);
      } else {
        this.$router.push({ path: "/login", query: { redirect: route } });
      }
    },
  },
};
</script>


<style scoped>
@import url("@/assets/styles/sideNav.css");
</style>