<template>
  <div>
    <div class="section detail-recipe">
      <div class="recipe-title">{{ data.title }}
        <div class="bookmark-icon-style" @click="toggleBookmark(postid)">
          <unicon
          :name="isBookmarked ? 'bookmark' : 'bookmark'"
          :icon-style="isBookmarked ?  'solid' : 'line'"
          :fill="isBookmarked ? 'orange' : 'orange'"
          class="icon-bookmark-style"
          width="40px"
          height="40px"
        />
            </div>
      </div>
      <div class="recipe-date">
        <span>조회수&nbsp;{{viewCnt}}</span>
        <span>작성일&nbsp;{{ data.createdAt }}</span></div>
        <div class="horizontal-line othispage"></div>
      <div class="menu-content">
        <div class="thum-box">
        <div class="menu-image">
          <img :src="data.thumbnailImg" loading="lazy"/></div>
        </div>
        <pre class="menu-desc" v-html="data.content"></pre>  
        <div class="tag-container">
        <span class ="select-tag">#<span class="nbsp">{{data.countryList}}</span></span>
        <span class ="select-tag">#<span class="nbsp">{{data.wayList}}</span></span>
        <span class ="select-tag">#<span class="nbsp">{{data.ingredientsList}}</span></span>
        <span class ="select-tag">#<span class="nbsp">{{data.situationList}}</span></span>
        <span v-if="data.exclusiveTool" class="select-tag">#<span class="nbsp">{{data.exclusiveTool}}</span></span>

        
        </div>
        <div class="menu-info">
          <div class="info">
            <pre><span class="aaaaa"> 분량 <br></span>{{data.servingsList}}</pre>
          </div>
          <div class="info">
            <pre><span class="aaaaa"> 조리시간 <br></span>{{data.timeList}}</pre>
          </div>
          <div class="info">
            <pre><span class="aaaaa"> 가격 <br></span>{{data.difficultyList}}</pre>
          </div>
        </div>

<dlv class="like-container">
        <div class="like-box">
          <div class="like-count">{{ likes }}</div>
    <span class="like-button" @click="toggleLike">
      <span>
         <unicon
        name="thumbs-up"
        class="thumbs-icon"
        width="20px"
        height="20px"
      />
      </span>
      {{ isLiked ? '취소' : '추천' }} 
      <!-- 모달 컴포넌트 -->

    </span>
  </div>
  <div class="bookmark-area">
  <span class="bookmark-button" style="margin-top:1.7rem; margin-left: 2rem;" @click="toggleBookmark(postid)">
      <span>
         <unicon
        name="favorite"
        class="thumbs-icon"
        width="20px"
        height="20px"
      />
      </span>
      {{ isBookmarked ? '북마크 해제' : '북마크 ' }} 

    </span>
  </div>
  <div class="mymemo-area">
    <span class="memo-button"
    style="margin-top:1.7rem; margin-left: 2rem;"
    @click="openMemo"
    >
    <span>
      <unicon
      name="file-edit-alt"
      class="thumbs-icon"
      width="20px"
      height="20px"
      />
    </span>
    메모
  </div>
      </div>
      </div>
       <Modal ref="modal" @confirm="deleteRecipe" @modalClosed="onModalClosed" />
      <div class="devider" />
      <div class="none" style="display: flex">
        <div class="section-title bigger" style="text-align: center;">
          재료
          <div class="ingredients-list">
            <div
              v-for="(ingredient, index) in data.ingredientData"
              :key="index"
              class="ingredient"
            >
              <span class="ingredient-name">- {{ ingredient.name }}&nbsp;</span>
              <span class="ingredient-qrt">{{ ingredient.qty }}</span>
              <span class="ingredient-unit">{{ ingredient.unit }}</span>
            </div>
          </div>
        </div>
        <div class="devider-next" />
        <div class="section-title bigger" style="text-align: center;">
          양념장
          <div class="ingredients-list">
            <div
              v-for="(sauce, index) in data.sauceData"
              :key="index"
              class="ingredient"
            >
              <span class="ingredient-name">- {{ sauce.name }}&nbsp;</span>
              <span class="ingredient-qrt">{{ sauce.qty }}</span>
              <span class="ingredient-unit">{{ sauce.unit }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="devider" />
      <div class="section-title bigger" style="text-align: left; padding: 5% 0;"><unicon
                name="restaurant"
                class="icon-restaurant-style"
                width="40px"
                height="40px"
              />조리방법</div>
      <div class="recipe-desc">
        <div class="cuisine-list">
 
          <div v-for="(cuisine, index) in data.cuisineData" :key="index">
                     <div class="um">
            <span class="cuisine-step"><span class="size-up-text">S</span>tep<span class="nbsp plus">{{ index + 1 }}</span></span> <!-- 단계 번호 추가 -->
            <div class="cuisine">
              <div class="cuisineContent">
                 
                <span class="cuisineText" v-html="formatCuisineText(cuisine.text)"
                  ></span
                >
                <span class="cuisineImg">
                  <img :src="cuisine.img" @click="openModalImg(cuisine.img)"/>
                </span>
              </div>
            </div>
            <div v-if="showModalImg" class="modalImg">
  <div class="modal-content-Img">
    <span class="closeImg" @click="closeModalImg">&times;</span>
    <img :src="selectedImage" alt="Selected Image" class="modal-image-Img" />
  </div>
</div>
</div>
            <!-- cuisine div 외부에 아이콘 배치 -->
            <div class="iconWrapper">
              <div class="iconWrapper" v-if="index !== data.cuisineData.length - 1">
                <unicon
                name="arrow-circle-down"
                class="icon-arrow-style"
                width="50px"
                height="50px"
              />
            </div>
          </div>
        </div>

        <div class="cuisineTip-box">
          <div>
            <button class="cuisineTip-btn"
            @click="openPopupWindow">조리 Tips</button>
          </div>
          <span class="first-tip"
          @click="openPopupWindow">{{firstItem.content}}<span>더보기 →</span></span> 
        </div>

        <!-- 각 cuisine 내부 아래에 아이콘을 추가 -->
        <Recipe
          :writer="userData.displayName"
          :writerImg="userData.userImg"
          :writerDesc="formattedText"
          :writerEmail="userData.email"
        />
      </div> 

<span hidden class="btn write" style="margin-left: 90%;"
          @click="confirmDelete">삭제</span>


      <div class="devider" />
      <div class="section-title bigger" style="text-align: left">리뷰</div>
      <div class="review-list" style="margin-bottom:5rem;">
        
        <div v-for="review in reviews" :key="review._id">
           <div class="review-box">
            <div class="review-thumbnail">
              <img :src="review.thumbnail">
            </div>
            <div class="review-contents-box">
              <div class="col-line">
            <span class="review-img">
              <img :src="review.userImg">
              </span> 
              <span style="margin-top:4px;">
        <span class="review-name">{{ review.nickName}}</span>
        <span class="review-data">{{review.createdAt}}</span>
        <span v-if="review.author === userEmail">
          <unicon
          name="times-square"
          class="icon-square-style"
          style="top: 3px;"
          width="15px"
          height="15px"
          @click="deleteReview(review._id)"
          />
        </span>
              </span>
              </div>
        
      
      <div class="review-text" v-html="formattedComment(review.content)"></div> 
            </div>
        
            </div> 
  </div>


      </div>
      <div class="section-title bigger" style="text-align: left">리뷰 작성</div>
      <div class="review">
        <div
          class="review-thumbnail  img-input2"
          :style="thumbnail ? { backgroundImage: `url(${thumbnail})` } : {}"
          @click="triggerThumbnail"
        >
          <!--<img v-if="imageSrc" :src="imageSrc" class="uploaded-image" />-->
          <span v-show="!thumbnail">클릭하여 사진을 업로드하세요</span>
        </div>
        <input
        type="file"
        ref="thumbnailInput"
        style="display: none"
        accept=".jpg, .jpeg, .png"
        @change="handleFileChange"
      />
        <div class="review-contents">
          <div class="review-text">
            <textarea v-model="newReview" placeholder="리뷰를 작성해보세요"/>
          </div>
        </div>
      </div>
      <div class="btn-area">
        <!-- <span class="btn">사진 등록</span> -->
        <span class="btn write" style="margin-bottom:10rem; margin-top: -2rem;
    margin-right: 2.5rem;" @click="submitReview">작성</span>
      </div>
    </div>
  </div>
</template>

<script>
import Recipe from "@/components/Recipe.vue";
import Review from "@/components/Review.vue";
import StarRating from "vue-star-rating";
import axios from "axios";
import { getAuth } from "firebase/auth";
import { mapGetters, mapMutations } from "vuex";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "@/main.js";
import Modal from "../components/Modal.vue";

export default {
  computed: {
    ...mapGetters(["getUser"]),
  },
  components: {
    Recipe,
    Review,
    StarRating,
    Modal,
  },
  data() {
    return {
      firstItem: "",
      likes: 0,
      isLiked: false,
      recipedId: "",
      userEmail: "",

      isBookmarked: false,
      recipeIndex: null,
      inputRate: 0,
      imageSrc: null,

      title: "",
      content: "",
      thumbnail: "",
      methods: "",
      country: "",
      ingredients: "",
      situation: "",
      servings: "",
      time: "",
      level: "",
      ingredientData: [],
      sauceData: [],
      cuisineData: [],
      data: "",
      userData: "",
      userBio: "",
      formattedText: "",

      loginemail: "",
      postid: "",

      viewCnt: "",

      newReview: "",
      reviews: [],
      thumbnail: "",
      recipeDeleted: false,

      showModalImg: false,
      selectedImage: "", // 모달에 띄울 이미지
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.fetchData();
    this.getRecipeData();
    this.getViewCnt();
    this.hiddenButton();
    this.loadTips();
  },
  mounted() {
    this.checkBookmark();
    this.checkLike();
    this.getReviews();
  },
  methods: {
    async checkLike() {
      this.recipedId = this.$route.params.id; //레시피아이디
      //console.log(`유저메일`,this.getUser);
      if (this.getUser == null) {
        const checkLiked = await axios.get(
          `/api/categories/toggle-like/${this.recipedId}`
        );
        this.likes = checkLiked.data.likes;
        // console.log(`좋아요수`, this.likes);
      } else {
        this.userEmail = this.getUser.email;

        try {
          const response = await axios.get(
            `/api/categories/toggle-like/${this.recipedId}`,
            {
              params: { email: this.userEmail },
            }
          );
          //console.log(response);
          this.likes = response.data.likes; //좋아요 수 설정
          //console.log(`좋아요수`, this.likes);
          this.isLiked = response.data.isLiked; //좋아요 상태 설정
          //console.log(`토글좋아요상태:`,this.isLiked)
        } catch (error) {
          console.error(`좋아요초기값가져오기실패`, error);
        }
      }
    },

    async toggleLike() {
      this.recipedId = this.$route.params.id; //레시피아이디
      if (this.getUser == null) {
        this.$refs.modal.openModal("로그인이 필요합니다");
      } else {
        this.userEmail = this.getUser.email; //유저이메일
        //console.log(this.getUser.email);
        try {
          const response = await axios.post(
            `/api/categories/toggle-like/${this.recipedId}`,
            {
              email: this.userEmail,
            }
          );
          this.likes = response.data.likes;
          this.isLiked = !this.isLiked;
        } catch (error) {
          console.error(`좋아요토글 에러발생`, error);
        }
      }
    },

    //서버에서 북마크 여부를 확인하는 함수
    async checkBookmark() {
      if (this.getUser == null) {
        this.isBookmarked = false;
      } else {
        this.loginemail = await this.getUser.email;
        const email = this.loginemail;
        try {
          const response = await axios.get(`/api/bookmark/${email}`);
          //console.log(`응답받은거`, response);
          this.isBookmarked = response.data.includes(this.postid);
          //console.log(`북마크조회`, this.isBookmarked);
        } catch (error) {
          console.error(`북마크조회에러남`, error);
        }
      }
    },
    //북마크를 추가하거나 제거하는 함수
    async toggleBookmark(postid) {
      if (this.getUser == null) {
        this.$refs.modal.openModal("로그인이 필요합니다");
      } else {
        try {
          if (this.isBookmarked) {
            //이미 북마크 되어있으면 제거 요청
            await axios.delete(`/api/bookmark`, {
              data: { userId: this.loginemail, postId: postid },
            });
            this.isBookmarked = !this.isBookmarked;
          } else {
            //북마크 안되어있으면 추가 요청
            await axios.post(`/api/bookmark`, {
              userId: this.loginemail,
              postId: postid,
            });
            this.isBookmarked = !this.isBookmarked;
            //console.log(this.isBookmarked);
          }
        } catch (error) {
          console.error(`에러남`, error);
        }
      }
      //console.log("isBookmarked:", this.isBookmarked);
    },
    formatCuisineText(text) {
      //const numberedText = (index + 1) + '. ' + text;
      return text.replace(/\n/g, "<br>");
    },
    fetchData() {
      this.recipeIndex = this.$route.params.id;
    },
    triggerFileInput() {
      // 숨겨진 file input을 트리거합니다.
      this.$refs.fileInput.click();
    },
    /*
    onFileSelected(event) {
      // 파일이 선택되면 읽고 이미지 소스로 사용합니다.
      const files = event.target.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(files[0]);
      }
    },*/
    async getRecipeData() {
      try {
        const id = this.$route.params.id;
        this.postid = id;
        //console.log(`id:`, id);
        const response = await axios.get(`/api/categories/detail/${id}`, {});
        //console.log(`response:`,response.data.title);
        //this.title = response.data.title;
        //console.log(`data:`,response.data);
        //console.log(`data2:`,response.data.data2);

        this.data = response.data.data;
        //console.log(`여기`, this.data);
        this.userData = response.data.data2;
        this.userBio = response.data.data3;
        this.formattedText = this.userBio.replace(/\n/g, "<br>");
        //console.log(`여어기이`,this.userData);
        //console.log(this.userBio);
        //console.log(`이미지url:`,this.userData.userImg);
        //this.thumbnail = response.data.thumbnailImg;
        //this.formattedText = this.myBio.replace(/\n/g, "<br>");
      } catch (error) {
        console.error(`겟레시피데이터오류`, error);
      }
    },
    async deleteRecipe() {
      const id = this.$route.params.id;
      try {
        const response = await axios.delete(`/api/categories/detail/${id}`);
        this.$refs.modal.openModal("레시피 삭제 완료", false, true);
        this.recipeDeleted = true; // 삭제 완료 상태 설정
      } catch (error) {
        console.error("삭제 실패:", error);
      }
    },
    async getViewCnt() {
      this.recipedId = this.$route.params.id; //레시피아이디
      if (this.getUser == null) {
        const checkViews = await axios.post(
          `/api/categories/increase-view/${this.recipedId}`
        );
        this.viewCnt = checkViews.data.views;
      } else {
        this.userEmail = this.getUser.email; //유저이메일

        try {
          const response = await axios.post(
            `/api/categories/increase-view/${this.recipedId}`,
            {
              email: this.userEmail,
            }
          );
          this.viewCnt = response.data.views;
          //      console.log(`조회수`, this.viewCnt);
        } catch (error) {
          console.error(`조회수불러오기 에러남`, error);
        }
      }
    },

    //리뷰 불러오는 함수
    async getReviews() {
      const recipeId = this.$route.params.id;
      if (this.getUser == null) {
        const response2 = await axios.get(`/api/review/${recipeId}/reviews`);
        this.reviews = response2.data.reviews;
      } else {
        const author = this.getUser.email;

        try {
          const response = await axios.get(`/api/review/${recipeId}/reviews`, {
            params: {
              author,
            },
          });
          this.reviews = response.data.reviews; // 서버에서 불러온 댓글 목록 설정
          //this.formattedText = this.comments.content.replace(/\n/g, "<br>");
          //  console.log(`리뷰목록 불러오기 성공`);
        } catch (error) {
          this.errorMessage =
            "리뷰 목록 불러오기 실패: " + error.response.data.error;
        }
      }
    },
    // 리뷰 삭제 함수
    async deleteReview(reviewId) {
      const recipeId = this.$route.params.id; // 현재 게시물 ID 추출

      try {
        await axios.delete(`/api/review/${recipeId}/reviews/${reviewId}`); // 리뷰 삭제 요청
        this.$refs.modal.openModal("리뷰 삭제완료");

        this.getReviews();
      } catch (error) {
        this.errorMessage = "리뷰 삭제 실패: " + error.response.data.error; // 에러 메시지 표시
      }
    },

    async submitReview() {
      if (this.getUser == null) {
        this.$refs.modal.openModal("로그인이 필요합니다");
      } else {
        const recipeId = this.$route.params.id;
        const author = this.getUser.email;
        const content = this.newReview;
        const thumbnail = this.thumbnail;
        try {
          const response = await axios.post(`/api/review/${recipeId}/reviews`, {
            author,
            content,
            thumbnail,
          });
          this.reviews = response.data;
          this.newReview = "";
          (this.thumbnail = ""), this.$refs.modal.openModal("리뷰 작성완료");
          this.getReviews();
        } catch (error) {
          console.error(`리뷰작성 실패`, error);
        }
      }
    },

    triggerThumbnail() {
      this.$refs.thumbnailInput.click();
    },
    async handleFileChange(event) {
      const file = event.target.files[0]; // 선택된 파일을 가져옴
      if (!file) {
        // console.log("파일이 선택되지 않음."); // 파일 선택 안 됐을 때 로그
        return;
      }
      if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result; // 이미지를 화면에 바로 보여줌 (미리보기)
        };
        reader.readAsDataURL(file);

        const uniqueFileName = `${Date.now()}_${file.name}`; // 파일명을 유니크하게 생성 (타임스탬프 + 파일 이름)
        const storageRef = ref(storage, `reviewImg/${uniqueFileName}`); // Firebase Storage 경로

        // 파일을 Firebase Storage에 업로드
        const snapshot = await uploadBytes(storageRef, file);
        // console.log("파일이 Firebase Storage에 업로드됨:", snapshot); // 파일 업로드 로그

        // 업로드된 파일의 다운로드 URL을 가져옴
        const url = await getDownloadURL(snapshot.ref);
        //  console.log("업로드된 파일의 URL:", url); // 파일 URL 로그

        this.imageUrl = url; // Firebase에서 다운로드한 URL을 imageUrl에 저장하여 즉시 반영
        this.thumbnail = url; // 새로운 이미지 URL을 newPic에 저장 (프로필 업데이트를 위한 준비)
      }
    },
    formattedComment(content) {
      if (content) {
        // \n을 <br>로 변환하여 반환
        return content.replace(/\n/g, "<br>");
      }
      return content;
    },
    onModalClosed() {
      // 삭제 완료 후 모달이 닫혔을 때만 라우팅
      if (this.recipeDeleted) {
        this.$router.push("/categories");
      }
    },
    confirmDelete() {
      // 삭제 확인 모달창 열기 (삭제 모드)
      this.$refs.modal.openModal("레시피를 삭제하시겠습니까?", true);
    },
    //로그인한 본인의 게시물이면 수정이랑 삭제버튼 보이게
    async hiddenButton() {
      if (this.getUser) {
        try {
          const id = this.$route.params.id;
          const author = await axios.get(`/api/categories/detail/${id}/option`);
          this.loginName = this.getUser.email;

          if (author.data.email == this.loginName) {
            document.querySelector(".btn.write").hidden = false;
          }
        } catch (error) {
          console.error("Error fetching author:", error);
        }
      }
    },
    openModalImg(imageUrl) {
      this.selectedImage = imageUrl; // 선택된 이미지 URL을 저장
      this.showModalImg = true; // 모달을 열기
      document.body.style.overflow = "hidden"; // 스크롤 비활성화
    },
    closeModalImg() {
      this.showModalImg = false; // 모달을 닫기
      this.selectedImage = ""; // 선택된 이미지 초기화
      document.body.style.overflow = "";
    },
    openPopupWindow() {
      const recipeId = this.$route.params.id;

      //const url = `http://localhost:3000/categories/detail/${recipeId}/tipId`;
      const url = `/categories/detail/${recipeId}/tipId`;
      const name = "팁목록";
      const specs =
        "width=1200, height=700,toolbar=no,status=no,menubar=no,scrollbars=yes,resizable=yes";

      window.open(url, name, specs);
    },
    async loadTips() {
      const recipeId = this.$route.params.id;
      try {
        const response = await axios.get(`/api/cuisinetip/${recipeId}/tips`);
        if (Array.isArray(response.data) && response.data.length > 0) {
          this.firstItem = response.data[0]; // 첫 번째 배열 항목만
        }
      } catch (error) {
        console.error("댓글로드에러", error);
      }
    },
    openMemo(){
      if (this.getUser == null) {
        this.$refs.modal.openModal("로그인이 필요합니다");
      }else{
      const recipeId = this.$route.params.id;
      const url = `/categories/detail/${recipeId}/memo`;
      const name = "레시피 메모";
      const specs =
        "width=1000, height=700,toolbar=no,status=no,menubar=no,scrollbars=yes,resizable=yes";

      window.open(url, name, specs);
}


    }
  },
  watch: {
    $route: "fetchData",
  },
};
</script>

<style scoped>
@import url("@/assets/styles/detail-recipe.css");
@import url("@/assets/styles/review.css");
</style>
