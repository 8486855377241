<template>
  <div v-if="isVisible" class="share-modal">
    <div class="share-modal-content">
      <span class="close-btn" @click="closeModal">&times;</span>
      <h3>공유하기</h3>
      <div class="copy-container">
        <input class="share-input-style" type="text" v-model="currentUrl" readonly />
        <button class="copy-button" @click="copyUrl">복사</button>
      </div>
      <div class="icon-linker">
        <img src="https://developers.kakao.com/assets/img/about/logos/kakaotalksharing/kakaotalk_sharing_btn_medium.png"
        @click="shareTo('kakao')"
        style="width:3rem;">

        <img src="https://user-images.githubusercontent.com/91887888/145205204-8041d35b-ee54-48f9-9c0d-32a5e01d2ac7.png"
        @click="shareTo('naver')"
        style="width:3rem; border-radius: 10px;">

        <img src="https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/icon%2Ffree-icon-twitter-logo-2496110.png?alt=media&token=29efafda-5bfb-44be-8533-32415ba28b3a"
        @click="shareTo('twitter')"
        style="width:3rem; border-radius: 10px;">

        <img src="https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/icon%2Fline.png?alt=media&token=0614afaa-e027-4213-914f-39518f712057"
        @click="shareTo('line')"
        style="width:3rem;border-radius:10px;">
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isVisible(newValue) {
   //   console.log("모달 상태 변경:", newValue);
      if (newValue) {
    //    console.log("모달이 열림");
      } else {
    //    console.log("모달이 닫힘");
      }
    },
    $route(to, from) {
      this.currentUrl = window.location.origin + this.$route.fullPath;
    }
  },
  data() {
    return {
      currentUrl: window.location.origin + this.$route.fullPath,
      title: 'Sizzle',
      description: '요리는 잘 모르지만 먹고는 싶다면?',
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2Fvegetables-9132660_1920%20(1).jpg?alt=media&token=9d3ebb81-5a42-442c-8193-8e44a9cc165e',
    };
  },
  created() {
    this.loadKakaoSdk(); // Kakao SDK 로드
  },
  methods: {
    loadKakaoSdk() {
      if (!window.Kakao) {
        const script = document.createElement("script");
        script.src = "https://developers.kakao.com/sdk/js/kakao.js";
        script.onload = () => {
          if (!window.Kakao.isInitialized()) {
            window.Kakao.init("020ccf14116c1dc63b52d826684a9084"); // .env 파일에서 JavaScript 키 불러오기
          }
        };
        document.head.appendChild(script);
      }
    },
    closeModal() {
      this.$emit("close");
    },
    copyUrl() {
      navigator.clipboard
        .writeText(this.currentUrl)
        .then(() => {
          alert("URL이 복사되었습니다!");
        })
        .catch((err) => {
          console.error("복사 실패: ", err);
        });
    },
    async shareTo(platform) {
      await this.getDataByRoute();
      let shareUrl = "";
      const encodedUrl = encodeURIComponent(this.currentUrl);
      const encodedTitle = encodeURIComponent("공유할 제목"); 
      switch (platform) {
        case "twitter":
          shareUrl = `https://twitter.com/share?url=${encodedUrl}`;
          break;
        case "band":
          shareUrl = `https://band.us/plugin/share?body=${encodedUrl}`;
          break;
        case "naver":
          shareUrl = `https://share.naver.com/web/shareView.nhn?url=${encodedUrl}&title=${encodedTitle}`;
          break;
        case "line":
          shareUrl = `https://line.me/R/msg/text/?${encodedUrl}`;
          break;
        case "kakao":
          if (window.Kakao && window.Kakao.isInitialized()) {
            window.Kakao.Link.sendDefault({
              objectType: "feed",
              content: {
                title: this.title,
                description: this.description,
                imageUrl: this.imageUrl,
                link: {
                  mobileWebUrl: this.currentUrl,
                  webUrl: this.currentUrl,
                },
              },
            });
          } else {
            alert("Kakao SDK가 초기화되지 않았습니다.");
          }
          return;
      }
      window.open(shareUrl, "_blank");
    },
     async getDataByRoute() {
      try {
        const path = this.$route.path;
        
        if (path.startsWith("/categories/detail/")) {
          const id = this.$route.params.id;
          const response = await axios.get(`/api/categories/detail/${id}`);
          const data = response.data.data;
          
          this.title = data.title;
          this.description = data.content;
          this.imageUrl = data.thumbnailImg;
        
        } /*else if (path.startsWith("/tables/")) {
          const id = this.$route.params.id;
          const response = await axios.get(`/api/tables/${id}`);
          const data = response.data;
          
          this.title = data.title;
          this.description = data.body;
        }*/
       else{
        this.title = 'Sizzle';
          this.description = '요리는 잘 모르지만 먹고는 싶다면?';
          this.imageUrl = 'https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2Fvegetables-9132660_1920%20(1).jpg?alt=media&token=9d3ebb81-5a42-442c-8193-8e44a9cc165e';
       }
      } catch (error) {
        console.error(`데이터를 가져오는 중 오류 발생:`, error);
      }
    },
  },
};
</script>

<style scoped>
@import url("@/assets/styles/share-modal.css");
</style>