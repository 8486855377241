<template>
  <div class="scrollTopBtn">
    <!--
    <button @click="routePush('/chatbot')" id="btn-chatbot">
      <unicon
        name="comment"
        class="icon-arrow-up"
        width="40px"
        height="40px"
      />
    </button>-->
    <button @click="openModal" id="btn-share-link">
      <unicon name="link" class="icon-arrow-up" width="40px" height="40px" />
    </button>
    <button @click="routePush_signCheck('/chat')" id="btn-start-chat">
      <unicon
        name="envelope"
        class="icon-arrow-up"
        width="40px"
        height="40px"
      />
    </button>
    <button @click="scrollToTop" id="btn-scroll-to-top">
      <unicon
        name="arrow-up"
        class="icon-arrow-up"
        width="40px"
        height="40px"
      />
    </button>
    <!-- ShareLink 모달 컴포넌트 -->
    <ShareLink :isVisible="isShareModalVisible" @close="closeModal" />
  </div>
</template>


<script>
import { mapGetters, mapMutations } from "vuex";
import ShareLink from "../components/ShareLink.vue";

export default {
  components: {
    ShareLink,
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  data() {
    return {
      isShareModalVisible: false, // 모달의 표시 여부
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },
    routePush(route) {
      if (this.$route.path !== route) {
        this.$router.push(route);
      }
    },
    routePush_signCheck(route) {
      if (this.getUser) {
        this.$router.push(route);
      } else {
        this.$router.push({ path: "/login", query: { redirect: route } });
      }
    },
    openModal() {
      this.isShareModalVisible = true; // 모달을 열기 위해 true로 설정
    //  console.log(
    //    "모달 열기 시도, isShareModalVisible:",
    //    this.isShareModalVisible
    //  );
    },
    closeModal() {
      this.isShareModalVisible = false; // 모달을 닫기 위해 false로 설정
    },
  },
};
</script>


<style scoped>
#btn-scroll-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999;
  border: none;
  outline: none;
  background-color:  #f8b437;
  fill: white;
  cursor: pointer;
  padding: 15px 15px 10px 15px;
  border-radius: 50%;
}

#btn-scroll-to-top:hover {
  background-color: var(--secondary-color);
}
#btn-start-chat {
  position: fixed;
  bottom: 105px;
  right: 30px;
  z-index: 999;
  border: none;
  outline: none;
  background-color: #f8b437;
  fill: white;
  cursor: pointer;
  padding: 15px 15px 10px 15px;
  border-radius: 50%;
}
#btn-start-chat:hover {
  background-color: var(--secondary-color);
}
#btn-share-link {
  position: fixed;
  bottom: 180px;
  right: 30px;
  z-index: 999;
  border: none;
  outline: none;
  background-color: #f8b437;
  fill: white;
  cursor: pointer;
  padding: 15px 15px 10px 15px;
  border-radius: 50%;
}
#btn-share-link:hover {
  background-color: var(--secondary-color);
}
#btn-chatbot{
  position: fixed;
  bottom: 255px;
  right: 30px;
  z-index: 999;
  border: none;
  outline: none;
  background-color: #f8b437;
  fill: white;
  cursor: pointer;
  padding: 15px 15px 10px 15px;
  border-radius: 50%;
}
#btn-chatbot:hover{
  background-color: var(--secondary-color);
}
</style>