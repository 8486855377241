<template>
  <div>
    <section class="notice">
      <div
      class="section-recipe-title addfont2"
      style="margin-bottom: 5rem; margin-left: -5.5rem"
    >
      <unicon
        name="restaurant"
        class="icon-restaurant-style plus"
        width="40px"
        height="40px"
      />
      나의 메모장
    </div>
      <div class="page-title">
        <div class="m-container" style="width=50%">
          <h1>메모장 목록</h1>
        </div>
      </div>

      <!-- board search area -->
      <div id="board-search">
        <div class="m-container">
          <div class="search-window">
            <form @submit.prevent="fetchRecipes">
              <div class="search-wrap">
                <label for="search" class="blind">레시피 검색</label>
                <input
                  id="search"
                  type="search"
                  v-model="searchQuery"
                  placeholder="검색어를 입력해주세요."
                />
                <button type="submit" class="m-btn m-btn-dark">검색</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- board list area -->
      <div id="board-list">
        <div class="m-container">
          <table class="board-table">
            <thead>
              <tr>
                <th scope="col" class="th-num">번호</th>
                <th scope="col" class="th-title">메모장 제목</th>
                <th scope="col" class="th-date">수정일</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="loading">
                <td colspan="3" style="text-align: center">로딩 중...</td>
              </tr>
              <tr v-else-if="recipes.length === 0">
                <td colspan="3" style="text-align: center">
                  검색 결과가 없습니다.
                </td>
              </tr>
              <tr v-for="(recipe, index) in filteredRecipes" :key="recipe._id">
                <td>{{ index + 1 }}</td>
                <th>
                  <a @click="routePushMemo(recipe._id)" style="cursor:pointer;">{{ recipe.title }}
                    <unicon
                      name="external-link-alt"
                      class="external-icon"
                      width="10px"
                      height="10px"
                      @click="routePushRecipe(recipe._id)"
                    />
                  </a>
                  <p v-if="recipe.description">{{ recipe.description }}</p>
                </th>
                <td>{{ createdAtDates[index] || "날짜 없음" }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      searchQuery: "", // 검색어
      recipes: [], // API에서 가져온 레시피 데이터
      loading: false, // 로딩 상태
      createdAtDates: [], // API에서 받아온 createdAt 데이터
      errorMessage: null, // 에러 메시지
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
    // 검색어를 기반으로 필터링된 레시피 반환
    filteredRecipes() {
      if (!this.searchQuery) return this.recipes;
      return this.recipes.filter((recipe) =>
        recipe.title.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  methods: {
    // 레시피 목록 가져오기
    async fetchRecipes() {
      const userId = this.getUser.email;
      this.loading = true;
      try {
        const response = await axios.get(`/api/memo/mymemo/${userId}`);
        if (response.data.success) {
          this.recipes = response.data.recipes;

          console.log(this.recipes);
        } else {
          console.error("데이터를 가져오는 데 실패했습니다.");
        }
      } catch (error) {
        console.error("서버 오류:", error);
      } finally {
        this.loading = false;
      }
    },
    navigateToRecipe(recipeId) {
      this.$router.push(`/categories/detail/${recipeId}/memo`);
    },
    // createdAt 데이터 가져오기
    async getMemoDate() {
      console.log(`함수호출`);
      const userId = this.getUser.email; // Vuex에서 사용자 ID 가져오기
      this.loading = true; // 로딩 시작
      this.errorMessage = null; // 에러 초기화

      try {
        console.log(`api호출`);
        // API 호출
        const response = await axios.get(`/api/memo/getdate/${userId}`);
        if (response.data.success) {
          // createdAt 데이터를 배열로 저장
          this.createdAtDates = response.data.data;
          console.log(`여기확인`, this.createdAtDates);
        } else {
          this.errorMessage = "데이터를 가져오는 데 실패했습니다.";
        }
      } catch (error) {
        console.error("서버 오류:", error);
        this.errorMessage = "서버에 문제가 발생했습니다.";
      } finally {
        this.loading = false; // 로딩 종료
      }
    },
     routePushRecipe(route) {
      if (this.$route.path !== route) {
        this.$router.push(`/categories/detail/${route}`);
      }
    },
    routePushMemo(route){
      if (this.$route.path !== route) {
        this.$router.push(`/categories/detail/${route}/memo`);
      }
    }
  },

  mounted() {
    // 컴포넌트가 마운트되면 데이터 가져오기
    this.fetchRecipes();
    this.getMemoDate();
  },
};
</script>
<style>
@import url("@/assets/styles/mymemo.css");
</style>