<template>
  <div>
    <div class="section">
      <div class="section-recipe-title addfont2">
        <unicon
          name="restaurant"
          class="icon-restaurant-style plus"
          width="40px"
          height="40px"
        />
        레시피 검색
      </div>
      <div class="sub-recipe-title">내 입맛에 맞는 요리를 찾아보세요</div>
      <div class="search-box">
        <input
          type="text"
          v-model="searchWord"
          class="searchBox"
          placeholder="레시피를 검색해보세요"
          @keypress.enter.prevent="searchRecipe"
        />
        <unicon
          name="search"
          @click="searchRecipe"
          class="search-icon"
          width="20px"
          height="20px"
        />
      </div>

      <!-- 필터 영역 -->
      <div class="box2" style="display: flex; margin-top: 3%">
        <div class="filter-container">
          <div class="filter">
            <div style="margin-bottom: 5px">방법별</div>
            <div class="box">
              <span
                v-for="(item, index) in selectedMethod"
                :key="index"
                :class="[
                  { 'filter-btn': true },
                  { selected: selectedSpan1 === index },
                ]"
                @click="selectSpan(item, index, 'method')"
              >
                {{ item }}
              </span>
            </div>
          </div>
          <div class="filter">
            <div style="margin-bottom: 5px">재료별</div>
            <div class="box">
              <span
                v-for="(item, index) in selectedIngredient"
                :key="index"
                :class="[
                  { 'filter-btn': true },
                  { selected: selectedSpan2 === index },
                ]"
                @click="selectSpan(item, index, 'ingredient')"
              >
                {{ item }}
              </span>
            </div>
          </div>
          <div class="filter">
            <div style="margin-bottom: 5px">상황별</div>
            <div class="box">
              <span
                v-for="(item, index) in selectedCase"
                :key="index"
                :class="[
                  { 'filter-btn': true },
                  { selected: selectedSpan3 === index },
                ]"
                @click="selectSpan(item, index, 'case')"
              >
                {{ item }}
              </span>
            </div>
          </div>
          <div class="filter">
            <div style="margin-bottom: 5px">국가별</div>
            <div class="box">
              <span
                v-for="(item, index) in selectedCountry"
                :key="index"
                :class="[
                  { 'filter-btn': true },
                  { selected: selectedSpan4 === index },
                ]"
                @click="selectSpan(item, index, 'country')"
              >
                {{ item }}
              </span>
            </div>
          </div>
        </div>

        <!-- 두 번째 필터 -->
        <div class="filter-container2">
          <div class="filter">
            <div style="margin-bottom: 5px">분량</div>
            <div class="box">
              <span
                v-for="(item, index) in selectedDishQty"
                :key="index"
                :class="[
                  { 'filter-btn': true },
                  { selected: selectedSpan5 === index },
                ]"
                @click="selectSpan(item, index, 'dishQty')"
              >
                {{ item }}
              </span>
            </div>
          </div>
          <div class="filter">
            <div style="margin-bottom: 5px">조리시간</div>
            <div class="box">
              <span
                v-for="(item, index) in selectedTime"
                :key="index"
                :class="[
                  { 'filter-btn': true },
                  { selected: selectedSpan6 === index },
                ]"
                @click="selectSpan(item, index, 'time')"
              >
                {{ item }}
              </span>
            </div>
          </div>
          <div class="filter">
            <div style="margin-bottom: 5px">가격대</div>
            <div class="box">
              <span
                v-for="(item, index) in selectedAmount"
                :key="index"
                :class="[
                  { 'filter-btn': true },
                  { selected: selectedSpan7 === index },
                ]"
                @click="selectSpan(item, index, 'amount')"
              >
                {{ item }}
              </span>
            </div>
          </div>
          <div class="filter">
            <div style="margin-bottom: 5px">전용도구</div>
            <div class="box">
              <span
                v-for="(item, index) in selectedExclusive"
                :key="index"
                :class="[
                  { 'filter-btn': true },
                  { selected: selectedSpan8 === index },
                ]"
                @click="selectSpan(item, index, 'exclusive')"
              >
                {{ item }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- 결과 영역 -->
      <div class="filter-results" ref="stage1" v->
        <span class="btn submit" @click="searchRecipe">적용하기</span>
        <transition name="fade1">
          <div
            class="result-text-area"
            v-show="isVisible"
            v-if="totalResults != 0"
          >
            <unicon name="angle-double-down" width="50" height="50" />
            <div>총 {{ totalResults }}개의 레시피가 있습니다</div>
          </div>
        </transition>

        <transition name="fade2">
          <div class="result-thumbnail-list" v-if="blankData">
            <div
              class="recipe-thumbnail"
              v-for="(recipe, index) in responseData"
              :key="recipe._id"
              @click="navigateToRecipe(recipe._id)"
            >
              <div class="thumnail-cover">
                <img
                  v-if="recipe.thumbnailImg"
                  :src="recipe.thumbnailImg"
                  alt="Thumbnail"
                />
                <!--북마크아이콘-->
                <div
                  class="bookmark-icon-style2"
                  @click.stop="toggleBookmark(recipe._id)"
                >
                  <unicon
                    :name="isBookmarked ? 'bookmark' : 'bookmark'"
                    :icon-style="recipe.isBookmarked ? 'solid' : 'line'"
                    :fill="recipe.isBookmarked ? 'orange' : 'orange'"
                    class="icon-bookmark-style over"
                    width="40px"  
                    height="40px"
                  />
                </div>
              </div>

              <span class="recipe-name">{{ recipe.title }}</span>
            </div>
          </div>
          <div v-show="isVisible && responseData.length == 0" class="no-data">
            등록된 레시피가 없습니다.
          </div>
        </transition>

        <!-- 무한 스크롤 추가 -->
        <infinite-loading @infinite="loadMore" ref="infiniteLoading">
          <span v-if="isEndOfResults">모든 데이터를 불러왔습니다.</span>
          <span slot="no-more"></span>
          <span slot="no-results"></span>
        </infinite-loading>
      </div>
          <Modal ref="modal"/>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import InfiniteLoading from "vue-infinite-loading"; 
import { mapGetters } from "vuex";
import Modal from '../components/Modal.vue'; 

export default {
  computed: {
    ...mapGetters(["getUser"]),
  },
  components: { // 컴포넌트 등록
    InfiniteLoading, 
    Modal,
  },
  data() {
    return {
      selectedMethod: [
        "국/탕",
        "조림",
        "볶음",
        "부침",
        "구이",
        "찜",
        "튀김",
        "찌개",
        "무침",
        "양념장/소스",
        "샌드위치",
        "샐러드",
        "디저트",
        "스프/죽",
        "베이킹",
      ],
      selectedIngredient: [
        "육류",
        "어패류",
        "해조류",
        "채소류",
        "곡류/콩류",
        "유제품류",
        "빵류",
        "면류",
        "과일류",
        "버섯류",
        "음료류",
        "알류",
        "가공식품류",
      ],
      selectedCase: [
        "간식",
        "다이어트",
        "일상식",
        "채식",
        "생일상",
        "손님접대",
        "명절",
        "도시락",
        "술상",
        "야식",
        "밑반찬",
        "브런치",
        "기념일",
      ],
      selectedCountry: [
        "한식",
        "일식",
        "양식",
        "중식",
        "이탈리아식",
        "동남아식",
        "멕시코식",
      ],
      selectedDishQty: ["1인분", "2인분", "3인분", "4인분", "5인분 이상"],
      selectedTime: [
        "간단 5분",
        "10분",
        "30분",
        "1시간",
        "2시간",
        "2시간 이상",
      ],
      selectedAmount: ["5,000원 이하", "10,000원대", "20,000원 이상"],
      selectedExclusive: ["전자레인지", "에어프라이어"],

      selectedSpan1: null, // 필터 선택 상태
      selectedSpan2: null,
      selectedSpan3: null,
      selectedSpan4: null,
      selectedSpan5: null,
      selectedSpan6: null,
      selectedSpan7: null,
      selectedSpan8: null,

      method: "",
      ingredient: "",
      case: "",
      country: "",
      dishQty: "",
      time: "",
      amount: "",
      exclusive: "",

      responseData: [], // 레시피 데이터
      totalResults: 0, // 전체 결과 개수
      currentPage: 1, // 현재 페이지
      pageSize: 8, // 페이지당 항목 수
      isEndOfResults: false, // 더 이상 불러올 데이터 여부
      searchWord: "", // 검색어
      isVisible: false, // 결과 영역 표시 여부
      isLoading: false, // 중복 API 요청 방지용 플래그
      blankData: false,
    };
  },
  created(){
    window.scrollTo(0, 0);
  },
  methods: {
    navigateToRecipe(idx) {
      this.$router.push(`/categories/detail/${idx}`);
    },
    selectSpan(item, index, type) {
      const spanMapping = {
        method: "selectedSpan1",
        ingredient: "selectedSpan2",
        case: "selectedSpan3",
        country: "selectedSpan4",
        dishQty: "selectedSpan5",
        time: "selectedSpan6",
        amount: "selectedSpan7",
        exclusive: "selectedSpan8",
      };
      this[spanMapping[type]] =
        this[spanMapping[type]] === index ? null : index;
      this[type] = this[spanMapping[type]] === null ? "" : item;
    },
    resetData() {
      this.responseData = [];
      this.currentPage = 1;
      this.isEndOfResults = false;
      if (this.$refs.infiniteLoading) {
        this.$refs.infiniteLoading.stateChanger.reset(); // 무한 스크롤 상태 리셋
      }
   //   console.log("resetData 호출됨 - 데이터 초기화 완료");
    },
    async loadMore($state) {
      if (this.isEndOfResults || this.isLoading) { //끝이면 완료상태로 변환
        $state.complete();
        return;
      }
      this.isLoading = true; 
      try {
        const response = await axios.get("/api/categories", { //필터 체크값 넘겨주면서 API요청
          params: {
            title: this.searchWord,
            method: this.method || null,
            ingredient: this.ingredient || null,
            case: this.case || null,
            country: this.country || null,
            dishQty: this.dishQty || null,
            time: this.time || null,
            amount: this.amount || null,
            exclusive: this.exclusive || null,
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        });
        const newRecipes = response.data.recipes; //레시피 데이터 받아옴
        if (newRecipes.length < this.pageSize) {  //새 데이터의 개수가 pageSize보다 적으면 끝에 도달했다고 표시
          this.isEndOfResults = true;
        }
        this.responseData = [...this.responseData, ...newRecipes]; //기존 데이터에 새로 가져온 데이터 추가 
        this.totalResults = response.data.totalResults;
        this.currentPage += 1;
        $state.loaded();   //상태 업데이트
        await this.checkBookmark(); //북마크 체크
        this.$forceUpdate();
      } catch (error) {
        console.error(`에러`, error);
        $state.complete();
      } finally {
        this.isLoading = false; 
      }
    },


    async searchRecipe() {
      this.blankData = true;
      window.scrollTo(0, 0);
      this.resetData();
      if (!this.isVisible) this.isVisible = true;

      await this.loadMore({ loaded: () => {} });

      this.smoothScroll();
      await this.checkBookmark();
    },

    smoothScroll() {
      this.$nextTick(() => {
        if (this.$refs.stage1) {
          this.$refs.stage1.scrollIntoView({ behavior: "smooth" });
        }
      });
    },

    async checkBookmark() {
      if(this.getUser==null){
        this.isBookmarked=false;
      }else{
      try {
        this.loginemail = await this.getUser.email;
        const email = this.loginemail;
        const response = await axios.get(`/api/bookmark/${email}`);
        const bookmarkedPosts = response.data; // 북마크된 포스트 ID 리스트

        // responseData에 북마크 상태를 동기화
        this.responseData.forEach((recipe) => {
          recipe.isBookmarked = bookmarkedPosts.includes(recipe._id);
        });
        this.$forceUpdate();
      } catch (error) {
        console.error(`북마크 조회 중 에러 발생`, error);
      }
      }
    },
    //북마크를 추가하거나 제거하는 함수
    async toggleBookmark(postId) {
      try {
        // 해당 postId를 가진 레시피를 찾음
        const recipe = this.responseData.find(
          (recipe) => recipe._id === postId
        );
        if (!recipe) {
          console.error(`레시피를 찾을 수 없습니다: ${postId}`);
          return;
        }

      //  console.log(`Before toggle:`, recipe.isBookmarked);

        if (recipe.isBookmarked) {
          // 이미 북마크 되어 있으면 제거 요청
          await axios.delete(`/api/bookmark`, {
            data: { userId: this.loginemail, postId: postId },
          });
          recipe.isBookmarked = false; // 북마크 상태 해제
        } else {
          // 북마크 안 되어 있으면 추가 요청
          await axios.post(`/api/bookmark`, {
            userId: this.loginemail,
            postId: postId,
          });
          recipe.isBookmarked = true; // 북마크 상태 추가
        }
        this.$forceUpdate();

   //     console.log(`After toggle:`, recipe.isBookmarked); // 상태가 변경되는지 확인
      } catch (error) {
        console.error(`에러남`, error);
      }
    },
  },
};
</script>

<style scoped>
/* 스타일은 그대로 유지 */
@import url("@/assets/styles/categories.css");
@import url("@/assets/styles/nanumneo.css");
</style>
