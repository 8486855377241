<template>
  <div>
    <div class="section">
      <div class="section-recipe-title addfont2">
        <unicon
          name="restaurant"
          class="icon-restaurant-style plus"
          width="40px"
          height="40px"
        />
        키친 스토리
      </div>
      <div class="search-box" style="float:right;width:30%;">
      <input
        type="text"
        v-model="searchWord"
        class="searchBox"
        style="width:85%;"
        placeholder="제목으로 검색"
        @keypress.enter.prevent="wordSearchTable(currentPage)"
      />
      <unicon
        name="search"
        class="search-icon"
        width="20px"
        height="20px"
        @click="wordSearchTable(currentPage)"
      />
    </div>
  
      <div class="result-thumbnail-list" id="myDiv">
    <!-- 16개의 인덱스와 posts 배열을 결합하여 사용 -->
    
    <div
      class="recipe-thumbnail"
      v-for="(post, index) in (searchActive ? searchResults : posts)" 
      :key="index"
      @click="navigateToRecipe(`${post._id}`)"
    >
      <!-- 본문 HTML에서 이미지를 추출하여 표시 -->
    
      <div class="thumnail-cover">
        <img :src="imageUrlData[index]" loading="lazy" alt=""/>
      </div>



      <span class="recipe-name">
        <!-- posts 배열의 데이터가 있으면 title 출력, 없으면 기본 값 출력 -->
        {{ post?.title }}
      </span>
      <!-- posts 배열의 데이터가 있으면 body 출력 
      <p v-if="posts[index]" v-html="posts[index].body">
      </p>-->
    </div>
  </div>
      </div>
      <!-- 페이지네이션 -->
      <div class="center">
    <div class="pagination">
      <button
        :disabled="currentPage === 1"
        @click="paging(); fetchPosts(currentPage - 1)"
      >
        &laquo;
      </button>

      <!-- 페이지 번호 표시 -->
      <span v-for="page in pagesToShow" :key="page">
        <button
          :class="{ active: page === currentPage }"
          @click="paging(); fetchPosts(page)"
        >
          {{ page }}
        </button>
      </span>

      <button
        :disabled="currentPage === totalPages"
        @click="paging(); fetchPosts(currentPage + 1)"
      >
        &raquo;
      </button>
    </div>
</div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";


export default {
  computed: {
    ...mapGetters(["getUser"]),
    pagesToShow() {
  let start = Math.max(this.currentPage - Math.floor(this.pagesToShowCount / 2), 1);
  let end = start + this.pagesToShowCount - 1;

  if (end > this.totalPages) {
    end = this.totalPages;
    start = Math.max(end - this.pagesToShowCount + 1, 1);
  }

  const pages = [];
  for (let i = start; i <= end; i++) {
    pages.push(i);
  }
  return pages;
}
  },
  
  data() {
    return {
      orderBy: "latest",
      posts:[],
      currentPage: 1,
      totalPages: 1,
      limit: 12,  // 페이지당 표시할 포스트 수
      pagesToShowCount: 8,  // 보여줄 페이지 번호 개수
      imageUrlData: [],
      searchWord:"",

      searchResults: [],   // 검색된 게시물 저장
      searchActive: false, // 검색 활성화 여부
    };
  },
  created() {
  this.fetchPosts(this.currentPage);
  window.scrollTo(0,0);
},
mounted(){
  this.extractImages();
},
  methods: {
  changeOrderBy(target) {
    this.orderBy = target;
  },
  navigateToRecipe(path) {
    this.$router.push(`/tables/${path}`);
  },
  
  async fetchPosts2(page) {
    try {
      //console.log(`페이지?`,page);
      const response1 = await axios.get('/api/tables', {
        params: {
          page: page,
          limit: this.limit
        }
      });
      this.posts = response1.data.posts;
      //console.log(`1번`,this.posts);
      this.currentPage = response1.data.currentPage;
      this.totalPages = response1.data.totalPages;
      //console.log(`일단여기확인 커런트페이지:`,this.currentPage);
      //console.log(`일단여기확인 토탈페이지:`,this.totalPages);
      //console.log(`2의posts`,this.posts);
      //console.log(`이건가?`,this.totalPages);
      this.extractImages();
      
    } catch (error) {
      console.error('데이터를 불러오는 중 오류 발생:', error);
    }
  },

async extractImages() {
    try {
      //console.log(`여기확인`,this.posts);
      const bodyValues = Object.values(this.searchActive ? this.searchResults : this.posts).map(post => post.body);
      //console.log(`여기확인2`, bodyValues); //여기에 지금 body값 배열 들어있음 
      //bodyValues.forEach(body => {
       // const imgSrc = extractImage(body);
      //  console.log(`추출된 이미지 URL:`, imgSrc);
      //  });
      //const bodyValues = this.posts.map(post => post.body);
      //console.log(bodyValues);
      //console.log(response.data.posts);
      const extractedImages = await bodyValues.map(body => this.extractImage(body));
      //console.log(`extractedImages확인: `,extractedImages); //여기에지금 src값만 있음
      this.imageUrlData = extractedImages;

    } catch (error) {
      console.error("에러났는데요", error);
    }  
  },

  extractImage(data) {
  const div = document.createElement('div');
  div.innerHTML = data;
  const img = div.querySelector('img');
  return img ? img.src : 'https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2FKakaoTalk_20241004_010718846.jpg?alt=media&token=bbd80d9e-5533-46fa-a77d-04a5fc9a7308'; // img 태그가 있으면 src 속성을, 없으면 디폴트 이미지
  },
  paging() {
    const myDiv = document.getElementById("myDiv");

    // 'hidden' 클래스를 추가해서 opacity를 0으로 만듦 (잠깐 사라짐)
    myDiv.classList.add("hidden");

    // 500ms 후에 다시 'hidden' 클래스를 제거해서 원래대로 보이게 함
    setTimeout(() => {
      myDiv.classList.remove("hidden");
    }, 400); // 0.5초 후 다시 원래 상태로 복구
  },
  //제목으로 검색
  async wordSearchTable(page){
    try {
       this.searchActive = true;
     // console.log(`페이지?`,page);
      const response1 = await axios.get('/api/tables/search', {
        params: {
          searchWord: this.searchWord,
          page: page,
          limit: this.limit
        }
      });
       this.searchResults = response1.data.findPost;
      //console.log(`1번`,this.posts);
      this.currentPage = response1.data.currentPage;
      this.totalPages = response1.data.totalPages;
      //console.log(`일단여기확인 커런트페이지:`,this.currentPage);
      //console.log(`일단여기확인 토탈페이지:`,this.totalPages);
      //console.log(`2의posts`,this.posts);
      //console.log(`이건가?`,this.totalPages);
      this.extractImages();
      
    } catch (error) {
      console.error('데이터를 불러오는 중 오류 발생:', error);
    }
  },
  async fetchPosts(page) {
      if (this.searchActive) {
        // 검색 중일 때는 검색 결과에서 페이지네이션
        this.wordSearchTable(page);
      } else {
        // 검색이 아닐 때는 전체 게시물에서 페이지네이션
        this.fetchPosts2(page);
      }
    },

  }
};
</script>

<style scoped>
@import url("@/assets/styles/tables.css");
@import url("@/assets/styles/pagination.css");
</style>
