<template>
  <div class="mymemo-box">
    <div class="memo-container">
      <div class="memo-title">{{ data.title }}의 메모장</div>
      <div>
        <div
          class="memo-underline"
          v-for="(value, index) in memos"
          :key="index"
        >
          {{ index + 1 }}.
          <template v-if="isEditing[index]">
            <input
              type="text"
              class="memo-content"
              v-model="tempMemos[index]"
            />
            <span>
              <unicon
                name="check-circle"
                class="check-circle-icon"
                width="20px"
                height="20px"
                @click="saveToIndex(index)"
              />
            </span>
          </template>
          <template v-else>
            <div class="memo-content">
              {{ memos[index] }}
            </div>
            <span>
              <unicon
                name="times-square"
                class="check-circle-icon"
                width="20px"
                height="20px"
                @click="deleteFromIndex(index)"
              />
            </span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["getUser"]),
  },
  data() {
    
    return {
      memos: Array(10).fill(''), // 저장된 메모 데이터 배열
      tempMemos: Array(10).fill(''), // 입력 중 데이터 배열
      isEditing: Array(10).fill(true), // 편집 상태 관리
      userId:'',
      data:'',
    };
  },
  methods: {
    async getRecipeData() {
      try {
        const id = this.$route.params.id;

        const response = await axios.get(`/api/categories/detail/${id}`, {});
     
        this.data = response.data.data;

      } catch (error) {
        console.error(`겟레시피데이터오류`, error);
      }
    },
    async fetchMemos() {
      const userId = this.getUser.email;
      const postId = this.$route.params.id;
      try {
        const response = await axios.get('/api/memo/getMemos', {
          params: { userId: userId, postId: postId },
        });

        // DB에서 가져온 데이터를 memos와 tempMemos에 반영
        response.data.memos.forEach((memo) => {
          this.$set(this.memos, memo.index, memo.content);
          this.$set(this.tempMemos, memo.index, memo.content);
          this.$set(this.isEditing, memo.index, false); // 저장된 데이터는 읽기 모드
        });
      } catch (error) {
        console.error('Failed to fetch memos:', error);
      }
    },
    async saveToIndex(index) {
      const userId = this.getUser.email;
      const postId = this.$route.params.id;
      try {
        const response = await axios.post('/api/memo/saveMemo', {
          userId: userId,
          postId: postId,
          index,
          content: this.tempMemos[index],
        });

        if (response.status === 200) {
      this.$set(this.memos, index, this.tempMemos[index]); // UI 업데이트
      this.$set(this.isEditing, index, false); // 읽기 모드로 전환
      console.log(`Memo at index ${index} saved.`);
    }
      } catch (error) {
        console.error('Failed to save memo:', error);
      }
    },


    async deleteFromIndex(index) {
      const userId = this.getUser.email;
      const postId = this.$route.params.id;
  try {
    // API 요청
    await axios.delete('/api/memo/deleteMemo', {
      data: {
        userId: userId, // 로그인 사용자 ID
        postId: postId, // 게시물 ID
        index: index, // 메모 인덱스
      },
    });

    // UI에서 해당 메모 제거
    this.$set(this.memos, index, '');
    this.$set(this.tempMemos, index, '');
    this.$set(this.isEditing, index, true);
    console.log(`Memo at index ${index} deleted.`);
  } catch (error) {
    console.error('Failed to delete memo:', error);
  }
}
  },
  mounted() {
    this.fetchMemos(); // 컴포넌트가 로드될 때 메모 데이터를 불러옴
    this.getRecipeData();
  },
};
</script>

<style>
@import url("@/assets/styles/memo.css");
</style>