<template>
  <div class="section">
    <div class="section-recipe-title addfont2">
      <unicon
        name="restaurant"
        class="icon-restaurant-style plus"
        width="40px"
        height="40px"
      />
      냉장고를 부탁해
    </div>
    <div class="sub-recipe-title second">
      냉장고 속 재료로 만들 수 있는 레시피를 알려드립니다
    </div>
    <div class="search-box">
      <input
        type="text"
        v-model="searchWord"
        class="searchBox"
        placeholder="재료를 검색해보세요"
        @keypress.enter.prevent="wordSearchRecipe"
      />
      <unicon
        name="search"
        class="search-icon"
        width="20px"
        height="20px"
        @click="wordSearchRecipe"
      />
    </div>
    <div class="image-container">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2Ffree-icon-fridge-10508396.png?alt=media&token=40b600c2-7e1f-42d4-9f44-4dc10ff8e99b"
        alt=""
      />
    </div>
    <div class="con1" ref="stage1" v-show="blacnkText && responseWord">
      <div class="appear-text">
        <span class="highlight-text">냉장고 속 재료</span>로 만들 수 있는
        <span class="highlight-text">요리</span>는
      </div>
      <unicon
        name="angle-double-down"
        width="50"
        height="50"
        style="width: 100%; text-align: center; margin-bottom: 2%"
      />

      <div class="result-thumbnail-list2" v-if="responseWord.length != 0">
        <div
          class="recipe-thumbnail"
          v-for="(recipe, index) in responseData"
          :key="recipe._id"
          @click="navigateToRecipe(recipe._id)"
        >
          <div class="thumnail-cover">
            <img
              v-if="recipe.thumbnailImg"
              :src="recipe.thumbnailImg"
              alt="Thumbnail"
            />
          </div>
          <span class="recipe-name">{{ recipe.title }}</span>
        </div>
      </div>

      <!-- 무한 스크롤 추가 -->
      <infinite-loading @infinite="loadMore" ref="infiniteLoading">
        <span v-if="isEndOfResults">모든 데이터를 불러왔습니다.</span>
          <span slot="no-results"></span>
          <span slot="no-more"></span>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import InfiniteLoading from "vue-infinite-loading"; // vue-infinite-loading import
export default {
  components: {
    InfiniteLoading, // 컴포넌트 등록
  },
  data() {
    return {
      blacnkText: "",
      searchWord: "",
      responseWord: "",
      responseData: [], // 레시피 데이터
      totalResults: 0, // 전체 결과 개수
      currentPage: 1, // 현재 페이지
      pageSize: 8, // 페이지당 항목 수
      isEndOfResults: false, // 더 이상 불러올 데이터 여부
      isVisible: false, // 결과 영역 표시 여부
      isLoading: false, // 중복 API 요청 방지용 플래그
    };
  },
  created(){
window.scrollTo(0, 0);
  },
  methods: {
    async wordSearchRecipe() {
      window.scrollTo(0, 0);
      this.resetData();
      if (!this.isVisible) this.isVisible = true;

      await this.loadMore({ loaded: () => {} });
      this.smoothScroll();
    },
    resetData() {
      this.responseData = [];
      this.currentPage = 1;
      this.isEndOfResults = false;
      if (this.$refs.infiniteLoading) {
        this.$refs.infiniteLoading.stateChanger.reset(); // 무한 스크롤 상태 리셋
      }
     // console.log("resetData 호출됨 - 데이터 초기화 완료");
    },
    navigateToRecipe(idx) {
      this.$router.push(`/categories/detail/${idx}`);
    },

    async loadMore($state) {
      if (this.isEndOfResults || this.isLoading) {
        $state.complete();
        return;
      }
      this.isLoading = true; // API 요청 중임을 표시
      this.blacnkText = this.searchWord;
      try {
        //console.log(`검색어:`, this.searchWord);
        const response = await axios.get("/api/categories/refrigerator", {
          params: {
            searchWord: this.searchWord,
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        });
        this.responseWord = response.data.recipes;
        //console.log("새 데이터:", this.responseWord);

        if (this.responseWord.length < this.pageSize) {
          this.isEndOfResults = true;
        }

        this.responseData = [...this.responseData, ...this.responseWord];
        //console.log("현재까지의 데이터:", this.responseData);

        this.totalResults = response.data.totalResults;
        this.currentPage += 1;

        $state.loaded();
        //console.log(`response:`,response);
      } catch (error) {
        console.log(`에러남`);
        $state.complete();
      } finally {
        this.isLoading = false;
        console.log(`종료`);
      }
    },
    smoothScroll() {
      this.$nextTick(() => {
        if (this.$refs.stage1) {
          this.$refs.stage1.scrollIntoView({ behavior: "smooth" });
        }
      });
    },
  },
};
</script>

<style scoped>
@import url("@/assets/styles/categories.css");
</style>