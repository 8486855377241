<template>
  <div class="section mypage">
    <div
      class="section-recipe-title addfont2"
      style="margin-bottom: 9rem; margin-left: -6.5rem"
    >
      <unicon
        name="restaurant"
        class="icon-restaurant-style plus"
        width="40px"
        height="40px"
      />
      북마크
    </div>
    <div class="profile-container">
      <div class="img-profile">
        <div class="imgBox">
         <img :src="getUser?.photoURL || this.imageUrl|| 'https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2FEFIv5HzUcAAdjhl.png?alt=media&token=939f81b6-d69b-4093-84f8-25c4bd69e36a'" />
        </div>
      </div>
      <div class="info-container">
        <div class="profile-item">{{ getUser?.displayName }}</div>
        <div class="profile-item user-name">{{ getUser?.email }}</div>
        <div
          class="profile-item user-introduction"
          v-html="formattedText"
        ></div>
      </div>
    </div>
    <div class="horizontal-line"></div>

    <div class="result-thumbnail-list" id="myDiv">
      <div
        class="recipe-thumbnail-mypost"
        v-for="(i, index) in [...Array(9).keys()]"
        :key="index"
        @click="navigateToRecipe(`${posts[index]._id}`)"
      >
        <div class="thumnail-cover">
          <img :src="imageUrlData[index]" alt="" />
        </div>
        <span class="recipe-name">{{ posts[index]?.title }}</span>
      </div>
    </div>
    <!-- 페이지네이션 -->
    <div class="centerButton">
      <div class="pagination">
        <button
          :disabled="currentPage === 1"
          @click="
            paging();
            getMyPost(currentPage - 1);
          "
        >
          &laquo;
        </button>

        <!-- 페이지 번호 표시 -->
        <span v-for="page in pagesToShow" :key="page">
          <button
            :class="{ active: page === currentPage }"
            @click="
              paging();
              getMyPost(page);
            "
          >
            {{ page }}
          </button>
        </span>

        <button
          :disabled="currentPage === totalPages"
          @click="
            paging();
            getMyPost(currentPage + 1);
          "
        >
          &raquo;
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getAuth } from "firebase/auth";
import axios from "axios";
export default {
  computed: {
    ...mapGetters(["getUser"]),
    pagesToShow() {
      let start = Math.max(
        this.currentPage - Math.floor(this.pagesToShowCount / 2),
        1
      );
      let end = start + this.pagesToShowCount - 1;

      if (end > this.totalPages) {
        end = this.totalPages;
        start = Math.max(end - this.pagesToShowCount + 1, 1);
      }

      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
  },

  data() {
    return {
      myBio: "",
      formattedText: "",
      posts: [],
      imageUrlData: [],

      pagesToShowCount: 8, // 보여줄 페이지 번호 개수
      currentPage: 1,
      totalPages: 1,
      limit: 9,
      userMail:"",
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.getMyPost(this.currentPage);
    this.getMyIntroduction();

    //this.extractImages();
  },
  methods: {
    async getMyIntroduction() {
      try {
        this.userMail = this.getUser.email;

        const response = await axios.post(`/api/mypage/mypost`, {
          email: this.userMail,
        });
        const responseData = response.data;
   //     console.log(`받은값->`, responseData);
        this.myBio = responseData;

        this.formattedText = this.myBio.replace(/\n/g, "<br>");
      } catch (error) {
        console.log(`에러내용:`, error);
      }
    },
    //로그인한사용자의 email을 req로 보냄
    //req로 받은 모든 post를 화면에 뿌려주고
    async getMyPost(page) {
      try {
        const email = this.getUser.email;
        //const userMail = this.getUser.email;
        const response = await axios.get(`/api/bookmark/user/${email}`, {
          params: {
            email: email,
            page: page,
            limit: this.limit,
          },
        });
        this.posts = response.data.marks;
        //console.log(`여기 확인입니다:`,response.data);
        this.currentPage = response.data.currentPage;
        this.totalPages = response.data.totalPages;
        this.extractImages();
      } catch (error) {
        console.error("데이터를 불러오는 중 오류 발생:", error);
      }
    },
    async extractImages() {
      const extractedImages = this.posts.map((item) => item.thumbnailImg);

      //console.log(`이거뭐지?`,extractedImages);
      this.imageUrlData = extractedImages;
    },

    extractImage(data) {
      const div = document.createElement("div");
      div.innerHTML = data;
      const img = div.querySelector("img");
      return img
        ? img.src
        : "https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2FKakaoTalk_20241004_010718846.jpg?alt=media&token=bbd80d9e-5533-46fa-a77d-04a5fc9a7308"; // img 태그가 있으면 src 속성을, 없으면 빈 문자열 반환
    },
    navigateToRecipe(path) {
      this.$router.push(`/categories/detail/${path}`);
    },
    paging() {
      const myDiv = document.getElementById("myDiv");

      // 'hidden' 클래스를 추가해서 opacity를 0으로 만듦 (잠깐 사라짐)
      myDiv.classList.add("hidden");

      // 500ms 후에 다시 'hidden' 클래스를 제거해서 원래대로 보이게 함
      setTimeout(() => {
        myDiv.classList.remove("hidden");
      }, 400); // 0.5초 후 다시 원래 상태로 복구
    },
    /*
    testLink() {
    this.$router.push(`/test`);
  },*/
  },
};
</script>

<style scoped>
@import url("@/assets/styles/my-post.css");
@import url("@/assets/styles/pagination.css");
</style>