<template>
  <div class="chat-app">
    <!-- 왼쪽에 메세지 목록 -->
    <div class="message-list">
      <h3>Messages</h3>

      <!-- 이메일 입력 박스와 버튼 -->
      <div class="new-chat">
        <input v-model="newChatEmail" placeholder="이메일 입력" />
        <div class="">
          <unicon
            name="message"
            class="icon-message-style"
            width="40px"
            height="50px"
            @click="startNewChat"
            @keypress.enter.prevent="startNewChat"
          />
        </div>
      </div>

      <!-- 기존 대화 목록 -->
      <div
        v-for="(chat, index) in chats"
        :key="chat.email + index"
        class="chat-item"
        @click="selectChat(chat.email)"
        :class="{ active: chat.email === selectedEmail }"
      >
        <p class="chat-email">{{ chat.nickname }}<span class="space-email">{{ chat.email }}</span> </p>
<div style="margin-left:auto;" @click.stop="deleteChat(chat.email, index)">
        <unicon
          name="times-square"
          class="icon-square-style plus"
          width="20px"
          height="20px"
        />
        </div>
      </div>
    </div>

    <!-- 오른쪽에 메세지 주고받는 창 -->
    <div class="chat-window">
      <div class="messages" ref="messageContainer">
        <div
          v-for="msg in messages"
          :key="msg.timestamp"
          :class="
            msg.senderEmail === userEmail
              ? 'message-outgoing-container'
              : 'message-incoming-container'
          "
        >
          <!-- 타임스탬프 표시 -->
          <span
            class="timestamp"
            :class="msg.senderEmail === userEmail ? 'time-right' : 'time-left'"
          >
            {{ msg.timestamp }}
          </span>

          <!-- 말풍선 -->
          <div
            :class="
              msg.senderEmail === userEmail
                ? 'message-outgoing'
                : 'message-incoming'
            "
          >
            <span>{{ msg.message }}</span>
          </div>
        </div>
      </div>

      <!-- 메시지 입력창 -->
      <div class="message-input">
        <input
          v-model="newMessage"
          placeholder="메세지를 입력하세요..."
          @keyup.enter="sendMessage"
        />
        <button @click="sendMessage">전송</button>
      </div>
    </div>
  </div>
</template>

<script>
import io from "socket.io-client";
import axios from "axios";
import { mapGetters } from "vuex";
import moment from 'moment';

export default {
  computed: {
    ...mapGetters(["getUser"]),
  },
  data() {
    return {
      userEmail: "", // 현재 로그인한 사용자 이메일
      selectedEmail: "", // 선택된 상대방의 이메일
      newChatEmail: "", // 새로 입력된 이메일 (채팅 시작을 위해 사용)
      chats: [], // 대화 목록 (상대방 이메일)
      messages: [], // 선택된 상대방과 주고받은 메시지
      newMessage: "", // 새로 작성한 메시지
      socket: null, // Socket.io 클라이언트 인스턴스
    };
  },
  created() {
  },
  mounted() {
    // Socket.io 연결 설정
    this.socket = io("https://www.ssizzle.net");

    // 로그인한 사용자의 이메일을 소켓 서버에 등록 (startpaging 이후)
    this.startpaging();

    // 서버로부터 메시지를 수신했을 때 처리
    this.socket.on("receiveMessage", (data) => {
   //   console.log("Message received from server: ", data); // 서버로부터 메시지 수신 로그

      // 로그가 제대로 나오지 않는다면, 아래 로그로 확인
    //  console.log("Selected email: ", this.selectedEmail);
   //   console.log("Message senderEmail: ", data.senderEmail);
   //   console.log("Message receiverEmail: ", data.receiverEmail);

      if (
        data.senderEmail === this.selectedEmail || // 상대방이 보낸 메시지
        data.receiverEmail === this.selectedEmail // 내가 보낸 메시지
      ) {
        this.messages.push(data); // 선택된 상대방과의 대화에만 표시
     //   console.log("Message added to the chat: ", data.message); // 메시지 추가
        // 새로운 메시지를 받은 후 스크롤을 아래로 이동
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      }
    });
  },
  methods: {
    async startpaging() {
      try {
        // getUser를 비동기적으로 호출하여 사용자 이메일을 설정
        const user = await this.getUser; // getUser는 함수이므로 ()로 호출
        this.userEmail = user.email;

        // userEmail이 설정된 후에 소켓 서버에 등록
        if (this.userEmail) {
          this.socket.emit("register", this.userEmail); // 이메일 등록
   //       console.log(`Registered user email: ${this.userEmail}`);
          await this.loadChats();
        }
      } catch (error) {
        console.error("Failed to get user email:", error);
      }
    },
    mounted() {
      // Socket.io 연결 설정
      this.socket = io("https://www.ssizzle.net");

      // 로그인한 사용자의 이메일을 소켓 서버에 등록
      this.startpaging();
      // 서버로부터 메시지를 수신했을 때 처리
      this.socket.on("receiveMessage", (data) => {

        if (
          data.senderEmail === this.selectedEmail || // 상대방이 보낸 메시지
          data.receiverEmail === this.selectedEmail // 내가 보낸 메시지
        ) {
          this.messages.push(data); // 선택된 상대방과의 대화에만 표시
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        }
      });
    },


    methods: {
      async startpaging() {
        try {
          // getUser를 비동기적으로 호출하여 사용자 이메일을 설정
          const user = await this.getUser; // getUser는 함수이므로 ()로 호출
          this.userEmail = user.email;

          // userEmail이 설정된 후에 소켓 서버에 등록
          if (this.userEmail) {
            this.socket.emit("register", this.userEmail); // 이메일 등록
   //         console.log(`Registered user email: ${this.userEmail}`);
          }
        } catch (error) { 
          console.error("Failed to get user email:", error);
        }
      },
    },
    // 사용자가 주고받은 대화 목록 불러오기
  async loadChats() {
  try {
    const response = await axios.get(`/api/chat/messages/${this.userEmail}`);
    
    // 서버 응답 데이터가 { messages: [...] } 형식인지 확인
    const messages = response.data.messages || []; // messages 배열을 추출하고 없으면 빈 배열
    
    const uniqueChats = [];

    // 메시지를 분석하여 대화 상대 목록 생성
    messages.forEach((msg) => {
      let otherEmail = "";
      let nickname = "";

      if (msg.senderEmail === this.userEmail) {
        otherEmail = msg.receiverEmail; // 내가 보낸 메시지 -> 상대방은 수신자
        nickname = msg.receiverNickname; // 서버에서 전달받은 닉네임
      } else if (msg.receiverEmail === this.userEmail) {
        otherEmail = msg.senderEmail; // 내가 받은 메시지 -> 상대방은 발신자
        nickname = msg.senderNickname; // 서버에서 전달받은 닉네임
      }

      // 중복되지 않게 상대방 이메일 추가
      if (
        otherEmail &&
        !uniqueChats.find((chat) => chat.email === otherEmail)
      ) {
        uniqueChats.push({ email: otherEmail, nickname: nickname });
      }
    });

    this.chats = uniqueChats; // 대화 목록 업데이트
 //   console.log("Chats loaded: ", this.chats);
  } catch (error) {
    console.error("Failed to load chats:", error);
  }
},

    // 새롭게 이메일을 입력하고 대화 시작
   async startNewChat() {
  if (!this.newChatEmail.trim()) return;

  // 서버에서 입력한 이메일에 해당하는 닉네임을 조회
  try {
    const response = await axios.get(`/api/chat/nickname/${this.newChatEmail}`);
    const nickname = response.data.nickname || "(없는 회원)"; // 닉네임이 없으면 기본값 설정

    // 대화 상대 선택 및 목록에 추가
    this.selectedEmail = this.newChatEmail;

    // 대화 목록에 추가 (이미 있는 경우 제외)
    if (!this.chats.find((chat) => chat.email === this.selectedEmail)) {
      this.chats.push({ email: this.selectedEmail, nickname: nickname });
    }

    // 입력창 비우기
    this.newChatEmail = "";

    // 대화 내역 초기화
    this.messages = [];

    this.$nextTick(() => {
      this.scrollToBottom();
    });
  } catch (error) {
    console.error("Failed to load nickname:", error);
  }
},

    // 대화 상대를 선택했을 때 해당 메시지 불러오기
    async selectChat(email) {
  this.selectedEmail = email; // 선택된 상대방의 이메일로 설정

  // 이전 메시지 목록 초기화
  this.messages = [];
  try {
    // 사용자가 선택한 상대방과의 메시지를 불러오기
    const response = await axios.get(`/api/chat/messages/${this.getUser.email}`);

    // 서버 응답 데이터가 { messages: [...] } 형식이라고 가정
    const messages = response.data.messages || []; // messages 필드를 사용하고, 없으면 빈 배열

    // 필터링하여 대화 목록 업데이트
    this.messages = messages.filter(
      (msg) =>
        (msg.senderEmail === email && msg.receiverEmail === this.userEmail) ||
        (msg.senderEmail === this.userEmail && msg.receiverEmail === email)
    );

    this.$nextTick(() => {
      this.scrollToBottom(); // 채팅방을 선택한 후 스크롤을 아래로 이동
    });
  } catch (error) {
    console.error("Failed to load messages:", error);
  }
},

    // 메시지 전송
    sendMessage() {
      if (this.newMessage.trim() === "" || !this.selectedEmail) return;

      // 서버로 메시지 전송
      const messageData = {
        senderEmail: this.userEmail, // 내가 보낸 메시지
        receiverEmail: this.selectedEmail, // 상대방 이메일
        message: this.newMessage,
        timestamp: moment().format('h:mm A'),
      };

 //     console.log("메세지보냅니다: ", messageData); // 메시지 전송 로그
      // 소켓 서버로 메시지 전송
      this.socket.emit("sendMessage", messageData);

      // 내가 보낸 메시지를 화면에 즉시 표시
      this.messages.push(messageData);
      this.newMessage = ""; // 입력창 비우기

      // 메시지를 보낸 후 스크롤을 아래로 이동
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },

    // 대화 목록에서 상대방과의 대화 삭제
    async deleteChat(email, index) {
      try {
        // DB에서 대화 삭제 요청
        await axios.delete(`/api/chat/delete/${this.getUser.email}/${email}`);

        // 대화 목록에서 삭제
          this.chats.splice(index, 1);
      //     console.log("Updated chats: ", this.chats); // 배열 상태 확인

        // 선택된 대화가 삭제된 경우, 대화 내용을 초기화
        if (this.selectedEmail === email) {
          this.messages = [];
          this.selectedEmail = "";
        }
         this.$forceUpdate();
      } catch (error) {
        console.error("Failed to delete chat:", error);
      }
    },
    deleteChatWithStop(event, email, index) {
    event.stopPropagation(); // 이벤트 전파 중지
    this.deleteChat(email, index); // 기존의 삭제 로직 호출
  },
    scrollToBottom() {
      const messageContainer = this.$refs.messageContainer;
      if (messageContainer) {
        messageContainer.scrollTop = messageContainer.scrollHeight;
      }
    },
  },
};
</script>



<style scoped>
@import url("@/assets/styles/chat.css");
</style>