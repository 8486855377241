<template>
  <div :class="['modal', isVisible ? 'show' : 'hide']">
    <div class="modal-content">
      <span class="close" @click="handleClose">&times;</span>
      <p>{{ message }}</p>

      <div class="button-area">
         <!-- 삭제 완료 모드일 때 확인 버튼만 보임 -->
        <span v-if="isCompleteMode" class="modal-button confirm" @click="handleClose">확인</span>
         <!-- 일반 모드에서는 확인 버튼만 보임 -->
        <span v-if="!isDeleteMode && !isCompleteMode" class="modal-button confirm" @click="handleClose">확인</span>

        <!-- 삭제 모드에서는 삭제와 취소 버튼이 보임 -->
        <span v-if="isDeleteMode && !isCompleteMode" class="modal-button cancel" @click="handleClose">취소</span>
        <span v-if="isDeleteMode && !isCompleteMode" class="modal-button delete" @click="confirmAction">삭제</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
       isVisible: false,
      message: '', // 모달에 보여줄 메시지
      isDeleteMode: false, // 삭제 모드 여부
       isCompleteMode: false, // 삭제 완료 모드 여부
    };
  },
   methods: {
    openModal(message, deleteMode = false, completeMode=false) {
      this.message = message;
      this.isVisible = true;
  //     console.log("isVisible 값:", this.isVisible);
      this.isDeleteMode = deleteMode; // 모달이 열릴 때 삭제 모드 여부 설정
       this.isCompleteMode = completeMode; // 삭제 완료 모드
    },
    
    handleClose() {
      this.isVisible = false;
      this.$emit("modalClosed");
    },
    confirmAction() {
  //    console.log("Modal의 confirmAction이 호출되었습니다."); // 로그 추가
      this.$emit('confirm'); // 삭제 액션을 부모 컴포넌트로 전달
      this.handleClose(); // 모달 닫기
    }
  }
};
</script>

<style scoped>
@import url("@/assets/styles/modal.css");
</style>
