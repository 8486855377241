<template>
  <div class="section">
    <div class="section-recipe-title addfont2">
        <unicon
          name="restaurant"
          class="icon-restaurant-style plus"
          width="40px"
          height="40px"
        />
        오늘 뭐 먹지?
      </div>
      <div class="food-container" id="food-container">
  <div class="image-container" id="imageContainer" @click="hiddenDiv">
    <img
      src="https://firebasestorage.googleapis.com/v0/b/recipe-vue-ffe87.appspot.com/o/defaultImage%2Ffree-icon-groceries-135763.png?alt=media&token=d21ce589-1e9c-47fd-917c-7577f3b7d055"
      alt=""
    />
  </div>
  <div class="sub-recipe-title third">오늘의 레시피를 무작위로 추천드립니다</div>
</div>
 <div class="btn-area plus">
          <span class="btn write plus" @click="getData" v-show="hiddenItem">⟲</span>
        </div>
<div class="box23" v-show="hiddenItem">
 
<div class="thumbnail-today">
  <img
        :src="pickItem.thumbnailImg"
        alt=""
      />
</div>
<div class="title-today">
  <span>{{pickItem.title}}</span>
</div>
<div class="content-today">
  <div class="detail-content">분량<br><div class="plus">{{pickItem.servingsList}}</div></div>
  <div class="detail-content">가격대<br><div class="plus">{{pickItem.difficultyList}}</div></div>
  <div class="detail-content">조리시간<br><div class="plus">{{pickItem.timeList}}</div></div>
  
  
  </div>
  <div>
  <span class="next-text" @click="navigateToRecipe(pickItem._id)">상세보기 ➜</span>
  </div>
</div>

</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      pickItem: "",
      allData: "",
      hiddenItem:false,
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.getData();
  },
  methods: {
    hiddenDiv() {
      // 클릭 시 food-container에 hidden 클래스 추가
      const foodContainer = document.getElementById('food-container');
      foodContainer.classList.add('hidden2');
    this.hiddenItem=true;

    },
    navigateToRecipe(idx) {
      this.$router.push(`/categories/detail/${idx}`);
    },
    async getData() {
      try {
        const response = await axios.get("/api/categories/randommenu");
        //console.log(response.data);
        this.allData = response.data;
        //랜덤으로 하나 뽑기이
        this.pickItem =
          this.allData[Math.floor(Math.random() * this.allData.length)];
        //console.log(`랜덤데이터`, this.pickItem);
      } catch (error) {
        console.log(`에러남`, error);
      }
    },
  },
};
</script>

<style scoped>
@import url("@/assets/styles/randomMenu.css");
</style>