<template>
  <div class="section">
    <div style="display: flex">
      <div style="min-width: 80%; margin: 0 auto">
        <div class="recipe-category">
          <span class="label" style="text-align: center">카테고리: </span>
          <select v-model="methods">
            <option selected disabled value="">방법별</option>
            <option
              v-for="methods in selectedMethod"
              :key="methods.id"
              :value="methods.value"
            >
              {{ methods.value }}
            </option>
          </select>
          <select v-model="country">
            <option selected disabled value="">국가별</option>
            <option
              v-for="country in selectedCountry"
              :key="country.id"
              :value="country.value"
            >
              {{ country.value }}
            </option>
          </select>
          <select v-model="ingredients">
            <option selected disabled value="">재료별</option>
            <option
              v-for="ingredients in selectedIngredient"
              :key="ingredients"
              :value="ingredients.value"
            >
              {{ ingredients.value }}
            </option>
          </select>
          <select v-model="situation">
            <option selected disabled value="">상황별</option>
            <option
              v-for="situation in selectedCase"
              :key="situation"
              :value="situation.value"
            >
              {{ situation.value }}
            </option>
          </select>
        </div>
        <div class="recipe-intro">
          <div class="intro-text">
            <div class="intro-container">
              <span class="label" style="text-align: center">제목: </span>
              <input
                class="recipe-title-input"
                placeholder="이곳에 제목을 입력하세요."
                v-model="title"
              />
            </div>
            <div
              class="intro-container"
              style="margin-top: 2rem; height: calc(100% - 3rem)"
            >
              <span class="label" style="text-align: center">소개:</span>
              <textarea class="recipe-intro-text" v-model="intro" />
            </div>
            <div class="intro-container" style="margin-top: 2rem; margin-left:1rem;">
              
              <span class="label" style="text-align: right">도구:</span>
              <select v-model="exclusive">
                <option selected disabled value="">도구</option>
                <option
                  v-for="exclusive in selectExclusive"
                  :key="exclusive.id"
                  :value="exclusive.value"
                >
                  {{ exclusive.value }}
                </option>
              </select>
              <span class="label" style="text-align: right">분량:</span>
              <select v-model="servings">
                <option selected disabled value="">분량</option>
                <option
                  v-for="servings in selectedDishQty"
                  :key="servings.id"
                  :value="servings.value"
                >
                  {{ servings.value }}
                </option>
              </select>
              <span class="label" style="text-align: right">가격:</span>
              <select v-model="difficulty">
                <option selected disabled value="">가격</option>
                <option
                  v-for="difficulty in selectedLevel"
                  :key="difficulty.id"
                  :value="difficulty.value"
                >
                  {{ difficulty.value }}
                </option>
              </select>
              <span class="label" style="text-align:right;">시간:</span>
              <select v-model="time">
                <option selected disabled value="">시간</option>
                <option
                  v-for="time in selectedTime"
                  :key="time.id"
                  :value="time.value"
                >
                  {{ time.value }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="recipe-ingredients">
          <div class="ing-container">
            <span class="label" style="width: fit-content; text-align: center"
              >메인재료:
            </span>
            <div style="display: flex; flex-direction: column; flex: 4">
              <!-- 아무 데이터 없으면 레이아웃 width가 안 맞아서 넣은 div -->
              <div class="ing-arr-container" v-show="mainIngArr.length == 0" />
              <div
                class="ing-arr-container"
                v-for="(item, index) in mainIngArr"
                :key="'mainIng' + index"
              >
                <!-- mainIngArr의 각 요소를 가져온다 -->
                <input
                  class="ing-input"
                  placeholder="재료명"
                  v-model="item.name"
                />
                <input
                  class="ing-input"
                  placeholder="수량"
                  v-model="item.qty"
                />
                <input
                  class="ing-input"
                  placeholder="단위"
                  v-model="item.unit"
                />
                <!-- 마이너스 버튼을 누르면 manIngArr에서 이 {index}번째의 요소를 삭제 -->
                <span class="ing-minus"
                :style="{ visibility: index === 0 ? 'hidden' : 'visible' }"
                @click="delMainIngContainer(index)"
                  ><unicon
                    name="minus"
                    fill="#fff"
                    style="position: relative; top: 3px"
                /></span>
              </div>
              <!-- 플러스 버튼을 누르면 MainIngArr에 { name: "", qty: "", unit: "" } 요소 추가 -->
              <div class="add-ing-btn" @click="addMainIngContainer">
                <unicon
                  name="plus"
                  fill="#fff"
                  style="position: relative; top: 2px"
                />
              </div>
            </div>
          </div>
          <div class="ing-container">
            <span class="label" style="width: fit-content; text-align: center"
              >양념장/소스:
            </span>
            <div style="display: flex; flex-direction: column; flex: 4">
              <!-- 아무 데이터 없으면 레이아웃 width가 안 맞아서 넣은 div -->
              <div
                class="ing-arr-container"
                style="width: 100%"
                v-show="subIngArr.length == 0"
              />
              <div
                class="ing-arr-container"
                v-for="(item, index) in subIngArr"
                :key="'subIng' + index"
              >
                <input
                  class="ing-input"
                  placeholder="재료명"
                  v-model="item.name"
                />
                <input
                  class="ing-input"
                  placeholder="수량"
                  v-model="item.qty"
                />
                <input
                  class="ing-input"
                  placeholder="단위"
                  v-model="item.unit"
                />
                <span class="ing-minus" 
                :style="{ visibility: index === 0 ? 'hidden' : 'visible' }"
                @click="delSubIngContainer(index)"
                  ><unicon
                    name="minus"
                    fill="#fff"
                    style="position: relative; top: 3px"
                /></span>
              </div>
              <div class="add-ing-btn" @click="addSubIngContainer">
                <unicon
                  name="plus"
                  fill="#fff"
                  style="position: relative; top: 2px"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="recipe-cuisine"
          v-for="(data, index) in cuisineArr"
          :key="'cuisine' + index"
          style="align-items:center;"
        >
          <div
            class="img-input"
            @click="triggerCuisineImg(index)"
            :ref="'cuisineImg' + index"
            :style="data.img ? { backgroundImage: `url(${data.img})` } : {}"
          >
            <span v-show="!data.img">클릭하여 사진을 업로드하세요</span>
          </div>
          <input
            type="file"
            :ref="'cuisineImgInput' + index"
            style="display: none"
            accept=".jpg, .jpeg, .png"
            @change="subHandleFileChange"
          />
          <textarea class="cuisine-text-input" 
          style="height:133px;"
          v-model="data.text" />
          <!-- 마이너스 버튼을 누르면 manIngArr에서 이 {index}번째의 요소를 삭제 -->
                <span class="ing-minus" 
                :style="{ marginLeft: '0.5rem', visibility: index === 0 ? 'hidden' : 'visible' }"
                @click="delCuisine(index)"
                  ><unicon
                    name="minus"
                    fill="#fff"
                    style="position: relative; top: 3px;"
                /></span>
        </div>
        <div class="add-ing-btn" @click="addCuisine">
          <unicon
            name="plus"
            fill="#fff"
            style="position: relative; top: 2px"
          />
        </div>
      </div>
      <div
        class="recipe-intro-thumbnail img-input"
        @click="triggerThumbnail"
        :style="thumbnail ? { backgroundImage: `url(${thumbnail})` } : {}"
      >
        <span v-show="!thumbnail">클릭하여 사진을 업로드하세요</span>
      </div>
      <input
        type="file"
        ref="thumbnailInput"
        style="display: none"
        accept=".jpg, .jpeg, .png"
        @change="handleFileChange"
      />
    </div>
    <div class="btn-area" style="margin-top: 5rem">
      <span class="btn write" @click="saveRecipe">저장하기</span>
      <Modal ref="modal" @modalClosed="onModalClosed" />
    </div>
  </div>
</template>

<script>
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "@/main.js";
import { mapGetters } from "vuex";
import { getAuth } from "firebase/auth";
import axios from "axios";
import Modal from "../components/Modal.vue";

export default {
  computed: {
    ...mapGetters(["getUser"]),
  },
  components: {
    Modal,
  },
  data() {
    return {
      selectedMethod: [
        { id: 1, value: "국/탕" },
        { id: 2, value: "조림" },
        { id: 3, value: "볶음" },
        { id: 4, value: "부침" },
         { id: 5, value: "구이" },
        { id: 6, value: "찜" },
        { id: 7, value: "무침" },
        { id: 8, value: "튀김" },
        { id: 9, value: "찌개" },
        { id: 10, value: "양념장/소스" },
        { id: 11, value: "샌드위치" },
        { id: 12, value: "샐러드" },
        { id: 13, value: "베이킹" },
        { id: 14, value: "디저트" },
        { id: 15, value: "스프/죽" },
      ],
      methods: "",
      selectedCountry: [
        { id: 1, value: "한식" },
        { id: 2, value: "일식" },
        { id: 3, value: "양식" },
        { id: 4, value: "중식" },
        { id: 5, value: "이탈리아식" },
        { id: 6, value: "동남아식" },
        { id: 7, value: "멕시코식" },
      ],
      country: "",
      selectedIngredient: [
        { id: 1, value: "육류" },
        { id: 2, value: "어패류" },
        { id: 3, value: "채소류" },
        { id: 4, value: "면류" },
        { id: 5, value: "곡류/콩류" },
        { id: 6, value: "유제품류" },
        { id: 7, value: "과일류" },
        { id: 8, value: "버섯류" },
        { id: 9, value: "가공식품류" },
        { id: 10, value: "해조류" },
        { id: 11, value: "음료류" },
        { id: 12, value: "빵류" },
        { id: 13, value: "알류" },
      ],
      ingredients: "",
      selectedCase: [
        { id: 1, value: "손님접대" },
        { id: 2, value: "생일상" },
        { id: 3, value: "간식" },
        { id: 4, value: "다이어트" },
        { id: 5, value: "채식" },
        { id: 6, value: "일상식" },
        { id: 7, value: "명절" },
        { id: 8, value: "도시락" },
        { id: 9, value: "술상" },
        { id: 10, value: "야식" },
        { id: 11, value: "밑반찬" },
        { id: 12, value: "브런치" },
        { id: 13, value: "기념일" },
      ],
      situation: "",
      selectedDishQty: [
        { id: 1, value: "1인분" },
        { id: 2, value: "2인분" },
        { id: 3, value: "3인분" },
        { id: 4, value: "4인분" },
        { id: 5, value: "5인분 이상" },
      ],
      servings: "",
      selectedTime: [
        { id: 1, value: "간단 5분" },
        { id: 2, value: "10분" },
        { id: 3, value: "30분" },
        { id: 4, value: "1시간" },
        { id: 5, value: "2시간" },
        { id: 6, value: "2시간 이상" },
      ],
      time: "",
      selectedLevel: [
        { id: 1, value: "5,000원 이하" },
        { id: 2, value: "10,000원대" },
        { id: 3, value: "20,000원 이상" },
      ],
      difficulty: "",

      selectExclusive: [
        { id: 1, value: "전자레인지" },
        { id: 2, value: "에어프라이어" },
        { id: 3, value: "기타" },
      ],
      exclusive: "",

      // 이 selected~ 에 있는 거 DB에 삽입하면 됨.

      // 여기두 DB 삽입할 정보들
      title: "", // 제목
      intro: "", // 소개

      thumbnail: null, // 썸넬
      mainIngArr: [{ name: "", qty: "", unit: "" }], // 메인 재료, 재료명/수량/단위 순
      subIngArr: [{ name: "", qty: "", unit: "" }], // 양념장, 재료명/수량/단위 순

      cuisineArr: [{ img: null, text: "" }], // 요리순서

      recipeid:"",
      checkaddRecipe:false,
    };
  },
  created(){
    window.scrollTo(0,0);
  },
  methods: {
    triggerThumbnail() {
      this.$refs.thumbnailInput.click();
    },
    triggerCuisineImg(index) {
      this.$refs[`cuisineImgInput${index}`][0].click();
    } /*
    handleThumbnailChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.thumbnail = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },*/,
    handleCuisineImgChange(event, index) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.$set(this.cuisineArr[index], "img", e.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
    addMainIngContainer() {
      this.mainIngArr.push({ name: "", qty: "", unit: "" });
    },
    delMainIngContainer(index) {
      this.mainIngArr.splice(index, 1);
    },
    addSubIngContainer() {
      this.subIngArr.push({ name: "", qty: "", unit: "" });
    },
    delSubIngContainer(index) {
      this.subIngArr.splice(index, 1);
    },
    addCuisine() {
      this.cuisineArr.push({ img: null, text: "" });
    },
    delCuisine(index){
      this.cuisineArr.splice(index,1);
    },
    async saveRecipe() {
      //console.log(getUser);
      //const userMail = getUser.email;
      const auth = getAuth();
      const userMail = auth.currentUser.email;
      //console.log(`1:`,this.difficulty,`2:`,this.time);
      // console.log(userMail,this.thumbnail);
      // console.log(`여기`,this.country);
      // console.log(this.mainIngArr);
      //console.log(`여기`, this.selectedCountry);
      //console.log(this.cuisineArr);
      try {
        const response = await axios.post("/api/recipe-edit", {
          email: userMail,
          title: this.title,
          content: this.intro,
          thumnail: this.thumbnail,
          methods: this.methods,
          country: this.country,
          ingredients: this.ingredients,
          situation: this.situation,
          servings: this.servings,
          time: this.time,
          difficulty: this.difficulty,
          exclusive: this.exclusive,
          mainIngArr: this.mainIngArr.map((item) => ({
            name: item.name,
            qty: item.qty,
            unit: item.unit,
          })),
          subIngArr: this.subIngArr.map((item) => ({
            name: item.name,
            qty: item.qty,
            unit: item.unit,
          })),
          cuisineArr: this.cuisineArr.map((item) => ({
            img: item.img,
            text: item.text,
          })),
        });
  
        this.recipeid= response.data.data._id;
        //console.log(`응답:`,response.data.data);
        this.checkaddRecipe=true;
        this.$refs.modal.openModal("새 레시피를 등록했습니다");
      } catch (error) {
        console.error("데이터를 저장하는 중 오류 발생:", error);
        this.$refs.modal.openModal(`필수 정보들을 입력해주세요`);

      }
    },
    async handleFileChange(event) {
      const file = event.target.files[0]; // 선택된 파일을 가져옴
      if (!file) {
       // console.log("파일이 선택되지 않음."); // 파일 선택 안 됐을 때 로그
        return;
      }
      if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result; // 이미지를 화면에 바로 보여줌 (미리보기)
        };
        reader.readAsDataURL(file);

        const uniqueFileName = `${Date.now()}_${file.name}`; // 파일명을 유니크하게 생성 (타임스탬프 + 파일 이름)
        const storageRef = ref(storage, `recipeThumbnail/${uniqueFileName}`); // Firebase Storage 경로

        // 파일을 Firebase Storage에 업로드
        const snapshot = await uploadBytes(storageRef, file);
       // console.log("파일이 Firebase Storage에 업로드됨:", snapshot); // 파일 업로드 로그

        // 업로드된 파일의 다운로드 URL을 가져옴
        const url = await getDownloadURL(snapshot.ref);
       // console.log("업로드된 파일의 URL:", url); // 파일 URL 로그

        this.imageUrl = url; // Firebase에서 다운로드한 URL을 imageUrl에 저장하여 즉시 반영
        this.thumbnail = url; // 새로운 이미지 URL을 newPic에 저장 (프로필 업데이트를 위한 준비)
      }
    },
    async subHandleFileChange(event) {
      const file = event.target.files[0]; // 선택된 파일을 가져옴
      if (!file) {
        //console.log("파일이 선택되지 않음."); // 파일 선택 안 됐을 때 로그
        return;
      }
      if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result; // 이미지를 화면에 바로 보여줌 (미리보기)
        };
        reader.readAsDataURL(file);

        const uniqueFileName = `${Date.now()}_${file.name}`; // 파일명을 유니크하게 생성 (타임스탬프 + 파일 이름)
        const storageRef = ref(storage, `cuisineImg/${uniqueFileName}`); // Firebase Storage 경로

        // 파일을 Firebase Storage에 업로드
        const snapshot = await uploadBytes(storageRef, file);
        //console.log("파일이 Firebase Storage에 업로드됨:", snapshot); // 파일 업로드 로그

        // 업로드된 파일의 다운로드 URL을 가져옴
        const url = await getDownloadURL(snapshot.ref);
       // console.log("업로드된 파일의 URL:", url); // 파일 URL 로그

        this.imageUrl = url; // Firebase에서 다운로드한 URL을 imageUrl에 저장하여 즉시 반영

        const emptyIndex = this.cuisineArr.findIndex(
          (item) => item.img === null
        );
        // 빈 공간이 있으면 그 인덱스에 넣고, 없으면 새로 추가
        if (emptyIndex !== -1) {
          // img 속성이 null인 객체가 있으면 해당 위치에 url 할당
          this.cuisineArr[emptyIndex].img = url;
        } else {
          // img 속성이 null인 객체가 없으면 배열에 새 객체 추가
          this.cuisineArr.push({ img: url, text: "" });
        }
      }
    },
    onModalClosed() {
      // 추가완료 후 모달이 닫혔을 때만 라우팅
      if(this.checkaddRecipe){
        this.$router.push(`/categories/detail/${this.recipeid}`);
  }
    },
  },
};
</script>

<style scoped>
@import url("@/assets/styles/recipe-edit.css");
</style>