<template>
  <div class="review">
    <div class="review-thumbnail"></div>
    <div class="review-contents">
      <div class="review-info">
        <span class="name">{{ reviewData.name }}</span> /
        <span class="date">{{ reviewData.date }}</span> /
        <span class="rate"
          ><StarRating
            class="starRating"
            :increment="0.5"
            :show-rating="false"
            :star-size="25"
            :inline="true"
            :read-only="true"
            :active-color="'#f8b437'"
            v-model="reviewData.rate"
        /></span>
      </div>
      <div class="review-text">
        <pre>{{ reviewData.text }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";

export default {
  props: {
    reviewData: {
      required: true,
      type: Object,
    },
  },
  components: {
    StarRating,
  },
};
</script>

<style scoped>

</style>
