<template>
  <div class="section login">
    <div class="login-area">
      <div class="section-title" style="text-align: center; margin: 0 0 30px 0">
        로그인
      </div>
      <div class="input-area" @keypress.enter.prevent="login">
        <div class="input-row">
          <unicon
            name="user-circle"
            class="icon-secondary-color"
            width="40px"
            height="40px"
          />
          <input v-model="email" />
        </div>
        <div class="input-row">
          <unicon
            name="keyhole-circle"
            class="icon-secondary-color"
            width="40px"
            height="40px"
          /><input type="password" v-model="password" />
        </div>
      </div>

      <span class="btn submit" @click="login">Login</span>
    </div>
      <Modal ref="modal"/>
  </div>

</template>

<script>
import {
  getAuth,
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import Modal from '../components/Modal.vue'; 

export default {
  components: {
    Modal,
  },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    async login() {
      if (!(this.email.trim().length > 0)) {
        this.$refs.modal.openModal("이메일을 입력해주세요");
      } else if (!(this.password.trim().length > 0)) {
        this.$refs.modal.openModal("비밀번호를 입력해주세요");
      } else {
        const auth = getAuth(); // Firebase auth 인스턴스 생성
        try {
          // 세션 지속성 설정: 브라우저 세션 스토리지에 로그인 정보를 저장
          await setPersistence(auth, browserSessionPersistence);

          // 이메일 및 비밀번호로 로그인
          const userCredential = await signInWithEmailAndPassword(
            auth,
            this.email,
            this.password
          );
          const user = userCredential.user;
          const redirectPath = this.$route.query.redirect || '/'; // 쿼리 파라미터가 있으면 해당 경로로, 없으면 index로
          this.$router.push(redirectPath);

          //this.$router.push("/");
        } catch (error) {
          this.$refs.modal.openModal("로그인 실패");
        }
      }
    },
  },
};
</script>

<style scoped>
@import url("@/assets/styles/signin-up.css");
</style>
