<template>
  <div class="section">
    <div class="section-recipe-title addfont2">
      <unicon
        name="restaurant"
        class="icon-restaurant-style plus"
        width="40px"
        height="40px"
      />
      레시피 메이트
    </div>
    <div class="chatbot-title">오늘의 날씨, 혹은 기분에 알맞는 레시피를 추천해주는 씨즐과 대화를 해보세요</div>
    <div class="chatbot-header" style="max-width: 780px; margin:0 auto; padding:15px; font-size:20px;border-top-left-radius:10px; border-top-right-radius:10px;"> Sizzle Chat </div>
    <iframe
      :src="streamlitUrl"
      width="100%"
      class="chatbot-iframe"
      height="600px"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      streamlitUrl: "https://sizzlechat.streamlit.app/?embed=true" // 배포된 Streamlit 앱 URL
    };
  }
};
</script>
<style scoped>
@import url("@/assets/styles/recipechatbot.css");
</style>

